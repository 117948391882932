import React from "react"
import ArrowLink from "blocks/ArrowLink"
import "./TextLinks.scss"

const TextLink = ({title,copy,entry,className=""}) => (
  <li className={`text-block-link ${className}`}>
    <h3>{title}</h3>
    <p>{copy}</p>
    <ArrowLink pageEntry={entry} linkCta={"Read More"} />
  </li>
)


export default TextLink
