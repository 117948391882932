import { useState, useEffect } from "react";
import fetch from "isomorphic-fetch";
import { useDispatch, useSelector } from "react-redux"
import { fetchData } from "redux/actions"

export default (query) => {
  const [data, setData] = useState(null)

  useEffect(() => {
    fetch(process.env.REACT_APP_API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        "Access-Control-Allow-Headers": "authorization"
      },
      body: query
    })
      .then(response => response.json())
      .then(setData)
      .catch(err => {
        console.log(query, err)
      })
  }, [query])
  return data
}

const setUrl = () => {
  let postURL = process.env.REACT_APP_API

  if (typeof window !== "undefined" && !!window.location.search) {
    postURL = window.location.search.match(/(x-craft-preview|draftId)/) ? `${postURL}${window.location.search}` : postURL
  }

  return postURL
}

export const useFetchStore = (query, endpoint) => {
  const body = JSON.stringify({ query })
  const data = useSelector(({ data }) => data[endpoint])
  const dispatch = useDispatch()
  if (!query) { return null }

  if (!data && typeof window !== "undefined") { //prevent fetch during ssr serve
    let postURL = setUrl()
    dispatch(fetchData(body, endpoint, postURL));
  }

  return data
}

export const useFetchStoreVars = (query, variables, endpoint) => {
  const body = JSON.stringify({ query, variables })
  const data = useSelector(({ data }) => data[endpoint])
  const dispatch = useDispatch()
  if (!query) { return null }

  if (!data && typeof window !== "undefined") { //prevent fetch during ssr serve
    let postURL = setUrl()
    dispatch(fetchData(body, endpoint, postURL));
  }

  return data
}
