import React from "react"
import { splitTag } from "helpers"

const label = tag => {
  switch (tag.typeHandle) {
    case "resource":
      return tag.resourceTag[0].title ? tag.resourceTag[0].title : "Resource"
    case "simpleResource":
      return tag.resourceTag[0].title ? tag.resourceTag[0].title : "ExternalResource";
    case "news":
      return tag.newsTag ? tag.newsTag[0].title : "News";
    case "story":
      return tag.storyType;
    case "action":
      return "Action";
    case "press":
      return "lac in the Press"
    case "chapteredPage":
      return "Webpage";
    case "standardPage":
      return "Webpage";
    case "people":
      return "Staff";
    default:
      return tag.typeHandle
  }
}

export default ({ className, tag }) => {
  let tagName = label(tag)
  return tag ? <aside className={`tag card-tag ${className}${tagName === "Action" ? " red" : ""}`}>
    {splitTag(tagName)}
  </aside> : null
}
