import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useTransition, a } from "react-spring"
import { useFetchStore, useFetchStoreVars } from "Fetch"

import { homeQuery as query } from "queries"
import { allNews } from "./Press/query"
import { sortData } from "blocks/Accordion"
import NewsModule from "blocks/NewsModule"
import Hero from "blocks/Hero"
import { PressPanel } from "./Press"
import LinkList from "blocks/LinkList"
import FactBlock from "blocks/FactBlock"
import Button from "blocks/Button"
import SectionHeader from "blocks/SectionHeader"
import Loading from "blocks/Loading"
import Module from "./Module"

import useBreakpoint from "useBreakpoint"

import "blocks/Modal.scss"

const ESCAPE_KEYS = ['27', 'Escape'];

const Home = () => {
  const homepageData = useFetchStore(query, "home")
  return <section className="home">
    {homepageData && homepageData.entries ? <HomepageBuilder data={homepageData} /> : <Loading />}
  </section>
}

const HomepageBuilder = ({ data }) => {
  const [openModal, setModalOpen] = useState(false)
  const [video, setVideo] = useState(null)
  const breakpoint = useBreakpoint()
  const pageData = data.entries[0]

  let heroButtons = pageData ? pageData.homePageBuilder.filter(block => block.typeHandle === "ctaLinks") : []
  let mobile = breakpoint === "mobile"

  // if (!pageData) window.location.reload();

  return pageData ? <article>
    {pageData.homePageBuilder.map(module => {
      const key = `${module.typeHandle}_${module.id}`
      switch (module.typeHandle) {
        case 'hero':
          let buttons = heroButtons[0]
          return <Hero
            key={key}
            image={module.image}
            heading={module.heroHeading}
            styleType={"home"}>
            {
              buttons ? <div className="buttons">
                {buttons.linkTo ? buttons.linkTo.map(link => <Button key={link.id} {...link} />) : null}
                {buttons.videoButton[0] ? <VideoButton setModalOpen={setModalOpen} setVideo={setVideo} {...buttons.videoButton[0]} /> : null}
              </div> : null
            }
          </Hero>
        case 'ctaLinks':
          return null

        case 'relatedContent':
          return <article key={module.id} className={`pad-top`} style={{
            paddingLeft: "1rem",
            paddingRight: "1rem"
          }}>
            <SectionHeader heading="Recent Highlights" className="large center max-width" />
            <div className={`${mobile ? "reorder" : ""} article-grid max-width`}>
              <FeaturedContent content={module.children} breakpoint={breakpoint} />
            </div>
          </article>

        case 'linkList':
          return <LinkList
            key={key}
            type={module.listStyle}
            data={module.internalLinkModule}
          />

        case 'factBlock':
          return <div key={key}>
            <FactBlock data={module.factBlock}>
              {module.linkTo ? <div className="buttons">{pageData.linkTo.map(link => <Button key={link.id} {...link} />)}</div> : null}
            </FactBlock>
          </div>
        case 'module':
          return module.modules.map(mod => <Module key={mod.id} module={mod} />)

        default:
          return null
      }
    })}

    <VideoModal setModalOpen={setModalOpen} openModal={openModal} video={video} />
  </article> : <Loading />
}

const FeaturedContent = ({ content, breakpoint }) => {
  const data = useFetchStoreVars(allNews, { section: "news", type: ["news", "action"] }, `all-news`)
  let featured = content[0] && content[0].featuredContent ? content[0] && content[0].featuredContent[0] : null
  let results = data ? data.entries.sort((x, y) => new Date(x.date) > new Date(y.date) ? -1 : 1) : data
  let mobile = breakpoint === "mobile"
  let resultsLength = breakpoint === "tablet" ? 2 : 4

  return <React.Fragment>
    {featured ? <NewsModule article={featured} featured={true} /> : null}
    <PressPanel className={mobile ? "pad-top" : ""} />
    {results ? <div className={"grid grid--50 grid--50-sm"}>
      {sortData(results).slice(0, resultsLength).map((article, i) => {
        let notFeatured = (featured && featured.id !== article.id) || !featured
        return notFeatured ? <NewsModule
          key={article.id}
          article={article}
          color="light-gray" />
          : null
      })}
    </div> : null}

    <div className={"news-button-alignment home-button-alignment center"}>
      <Link to="/news" className={`button blackBorder`} style={mobile ? { margin: "2rem 0" } : { margin: "2rem 0 0" }} title="load more">See More</Link>
    </div>
  </React.Fragment>
}

const closed = { opacity: 0 }
const open = { opacity: 1 }

const VideoButton = ({ videoAsset, linkCta, setModalOpen, setVideo }) => {
  return <button
    className="button blackBorder button-icon"
    onClick={() => {
      setModalOpen(true)
      setVideo(videoAsset[0])
    }}>
    {linkCta ? linkCta : "Watch Video"}
    <span className="play-icon"></span>
  </button>
}

const VideoModal = ({ setModalOpen, openModal, video }) => {
  const t = useTransition(openModal, null, {
    from: closed,
    enter: open,
    leave: closed
  })

  // close modal when escape key is pressed
  const handler = ({ key }) => {
    if (ESCAPE_KEYS.includes(String(key))) {
      setModalOpen(false);
    }
  };
  // event listeners for escape key
  useEffect(() => {
    window.addEventListener('keydown', handler);
    return () => {
      window.removeEventListener('keydown', handler);
    };
  });

  return t.map(({ item, key, props }) => item && <a.div
    key={key}
    style={props}
    className="modal"
    onBlur={() => { setModalOpen(false) }}>
    <button className="close reset" onClick={() => { setModalOpen(false) }}>
      <span className="close-icon"></span>
    </button>
    <video controls autoPlay>
      <source src={video.url} />
      <track default kind="captions" srclang="en" />
    </video>
  </a.div>
  )
}

export default Home
