export const types = `query($type:[String]) {
  categories(group: $type, id: ["not", 19923]) {
    groupHandle
    id
    slug
    title
  }
}`

export const issueAreas = `query {
  entries(section: "work", type: "issueArea", orderBy: "title") {
    id
    title
    slug
    uri
    sectionHandle
    ...on work_issueArea_Entry {
      shortDescription: heading
      parent {
        id
      }
    }
  }
}`
