import React from "react"
import InnerHTML from "dangerously-set-html-content";
import { BigLinkModule } from "blocks/LinkList"
import SplitList from "blocks/SplitList"
import FactBlock from "blocks/FactBlock"
import Frame from "blocks/Frame"
import Button from "blocks/Button"
import Image from "blocks/Image"
import BodyText from "blocks/BodyText"
import RelatedContent from "./RelatedContent"
import Module from "./Module"
import DonateBlock from "blocks/DonateBlock"

import "blocks/PageBlocks.scss"

const PageBuilder = ({ data, slug }) => {
  let impactPage = slug === "impact"
  return <article className={impactPage ? "reorder sidebar-stretch" : "sidebar-stretch"}>
    {data.map(module => {
      const key = `${module.typeHandle}_${module.id}`
      switch (module.typeHandle) {
        case 'linkModule':
          return <div className={"link-list--big max-width"} key={key}>
            {module.internalLinkModule.map(link => (
              <BigLinkModule
                key={`${link.typeHandle}_${link.id}`}
                page={link.pageEntry ? link.pageEntry[0] : null}
                heading={link.heading}
                explainer={link.explainer}
                buttonCopy={link.buttonCopy}
                accentColor={link.accentColor}
                image={link.imageAsset ? link.imageAsset[0] : null}
              />))}
            {module.children ? module.children.map(link => {
              return <div className={`link-module`} key={`${link.typeHandle}_${link.id}`}>
                <div className="link-module__content">
                  <h3>{link.copy}</h3>
                  <BodyText body={link.body} />
                  {link.linkTo.map(button => <Button key={button.id} {...button} />)}
                </div>
                <div className="link-module__image">
                  <Frame color={link.color}>
                    {link.image[0] ? <Image image={link.image[0]} className="full-width" /> : null}
                  </Frame>
                </div>
              </div>
            }) : null}
          </div>

        case 'splitList':
          return <SplitList key={key} blocks={module.children} />

        case 'articleModule':
          return <ArticleModule key={key} blocks={module.children} />

        case 'factBlock':
          return <FactBlock key={key} data={module.factBlock} />

        case 'module':
          return module.modules.map(mod => <Module key={mod.id} module={mod} />)

        default:
          return null
      }
    })}

    {impactPage ? <div className="bg-gray" style={{ backgroundColor: 'var(--bg-gray)' }}>
      <RelatedContent color="white" heading="Client Stories" type="story" storyType="clientStory" limit={6} />
    </div> : null}
  </article>
}

const ArticleModule = ({ blocks }) => {
  let blockClass = blocks.map(block => block.typeHandle).sort()
  return <div className={`flex-list link-module max-width ${blockClass.join('-')}`}>
    {blocks.map(block => {
      const key = `${block.typeHandle}_${block.id}`
      switch (block.typeHandle) {
        case "image":
          let image = block.image
          return <div key={key} className={`${block.typeHandle} link-module__image ${image.length > 1 ? "double" : ""}`}>
            {image.map(img => <Frame color={block.color} key={img.id}>
              <img
                src={`${img.imageTransforms.src}`}
                srcSet={`${img.imageTransforms.srcset}`}
                alt={img.title}
              // style={{transform: `translate('${img.focalPoint}')`}}
              />
            </Frame>
            )}
          </div>

        case "bodyCopy":
          return <div key={key} className={`${block.typeHandle} article link-module__content`}>
            <h3>{block.heading}</h3>
            <p>{block.copy}</p>
          </div>

        case "bodyBlock":
          return <div key={key} className={`${block.typeHandle} article link-module__content`}>
            <BodyText body={block.body} />
          </div>

        case "statistic":
          return <div key={key} className={`${block.typeHandle} fact statistic`}>
            <h3>{block.heading}</h3>
            <p>{block.copy}</p>
          </div>

        case "embed":
          return <InnerHTML key={key} html={block.embed} />

        case "donateBlock":
          return <div key={key} className={`${block.typeHandle} article bg--${block.color}`}>
            {block.heading && <h3 className={"max-width"}>{block.heading}</h3>}
            {block.copy && <p className={"max-width"}>{block.copy}</p>}
            <DonateBlock linkUrl={block.linkUrl} />
          </div>

        default:
          return null
      }
    })}
  </div>
}

export default PageBuilder
