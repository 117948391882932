import React from "react"
import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"
import SectionHeader from "../SectionHeader"
import Frame from "../Frame"
import BodyText from "../BodyText"
import { InternalLink as Button } from "../Button"
import Image, { ImgBg } from "../Image"
import Tag from "../Tag"

import "blocks/Cards.scss"
import "./LinkList.scss"

export default ({ type, data }) => {
  return <div className={type === "grid" ? "flex-list max-width pad-top" : "link-list--big max-width"}>
    {Object.values(data).map(section => {
      if (section.typeHandle === "heading") {
        return <SectionHeader key={section.id} heading={section.sectionHeading} />
      } else if (section.typeHandle === "linkTo") {
        let pageEntry = section.pageEntry ? section.pageEntry[0] : null
        return type === "grid" ?
          <GridLinkModule
            key={`${section.type}_${section.id}`}
            page={pageEntry}
            hashLink={section.hashLink}
            image={section.imageAsset[0]}
            heading={section.heading ? section.heading : (pageEntry ? pageEntry.title : null)}
            buttonCopy={section.buttonCopy}
            bgColor={"light-gray"}
            position={"back"}
          /> :
          <BigLinkModule
            key={`${section.type}_${section.id}`}
            page={pageEntry}
            hashLink={section.hashLink}
            image={section.imageAsset[0]}
            heading={section.heading ? section.heading : (pageEntry ? pageEntry.title : null)}
            explainer={section.explainer}
            buttonCopy={section.buttonCopy}
            accentColor={section.accentColor}
          />
      } else { return null }
    })}
  </div>
}

export const GridLinkModule = ({ page, hashLink, image, heading, copy, bgColor, position }) => {
  const url = typeof page === "object" ? page.uri : page
  const bg = bgColor ? bgColor : "gray"

  return hashLink ? <HashLink to={`/${url}#${hashLink}`} className={`grid-link card card-with-img card-img-lg img--${position ? position : "back"} ${bg}`}>
    {image ? <Image image={image} className="full-width" /> : <PlaceholderImg />}
    <div className="card-content" style={{ backgroundColor: `var(--bg-${bg})` }}>
      <h3>{heading}</h3>
      {copy ? <BodyText body={copy} /> : null}
    </div>
  </HashLink> : <Link to={`/${url}`} className={`grid-link card card-with-img card-img-lg img--${position ? position : "back"} ${bg}`}>
      {image ? <Image image={image} className="full-width" /> : <PlaceholderImg />}
      <div className="card-content" style={{ backgroundColor: `var(--bg-${bg})` }}>
        <h3>{heading}</h3>
        {copy ? <BodyText body={copy} /> : null}
      </div>
    </Link>
}


export const BigLinkModule = ({ page, hashLink, heading, explainer, buttonCopy, accentColor, image, className = "", children }) => {
  return <div className={`link-module ${className}`}>
    <div className="link-module__content">
      <h3>{heading}</h3>
      <p>{explainer}</p>
      {children ? children : null}
      <Button pageEntry={page} hashLink={hashLink} linkCta={buttonCopy} linkStyle={`defaultRed`} />
    </div>
    <div className="link-module__image">
      <Frame color={accentColor}>
        {image ? <ImgBg image={image} height={70} /> : <PlaceholderImg />}
      </Frame>
    </div>
  </div>
}

const PlaceholderImg = () => <figure className="image-centered full-width">
  {/*<img src="https://dummyimage.com/300x200/6AA07A/E9E9E6"
    alt="placeholder"
  />*/}
</figure>

export const TabLink = ({ page, color, image }) => {
  const isNews = page.sectionHandle === "news"
  const style = {
    backgroundColor: color ? `var(--bg-${color})` : "var(--bg-light-gray)"
  }

  return isNews ? <NewsTabLink page={page} /> : <Link to={`/${page.uri}`} title={`go to ${page.title} page`} className={`tab-link ${image ? "has-img" : "pad"}`} style={style}>
    {image ? <figure>
      <img src={image.imageTransforms.optimizedWebPImageUrls[image.imageTransforms.optimizedWebPImageUrls.length - 1]} alt={`icon for ${page.title}`} />
    </figure> : null}
    <div>
      <p>{page.title}</p>
      <span className="inline-link">
        Read More
        <span className="arrow" />
      </span>
    </div>
  </Link>
}

const NewsTabLink = ({ page }) => {
  return <Link to={`/${page.uri}`} title={`go to ${page.title} page`} className={`tab-link tab-link-news`}>
    <Tag tag={page} />
    <div>
      <p>{page.title}</p>
      <div className="tab-link-actions">
        <span className="publish-date">{page.date}</span>
        <span className="inline-link">
          Read More
          <span className="arrow" />
        </span>
      </div>
    </div>
  </Link>
}
