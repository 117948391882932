import React from "react"
import { Link } from "react-router-dom"
import { useFetchStore } from "Fetch"
import { fetchData } from "redux/actions";
import { query } from "./query"
import useBreakpoint from "useBreakpoint"
import NewsletterForm from "blocks/NewsletterForm"
import Twitter from "svgr/Twitter"
import Facebook from "svgr/Facebook"
import Linkedin from "svgr/Linkedin"
import Instagram from "svgr/Instagram"
import Youtube from "svgr/Youtube"

import "./Footer.scss"

const Footer = () => {
  const data = useFetchStore(query, "global-footer")
  const year = new Date()

  const breakpoint = useBreakpoint()
  let isMobile = breakpoint !== "desktop"

  return <footer className="site-footer pad-top pad-bottom">
    <div className="max-width">
      {data && <div className={isMobile ? "flex-list" : "grid grid--33"}>
        <div className="site-footer-section site-footer-details">
          <FooterDetails details={data} />
          {!isMobile ? <div className="site-footer-section site-footer-partners">
            {data.globalSets[0].partnerLogo.map(partner =>
              <a href={partner.linkUrl} target="_blank" rel="noopener noreferrer" title={`go to partner site`} key={`${partner.id}_${partner.typeHandle}`}>
                <img src={partner.logo[0].url} alt={"partner logo"} />
              </a>
            )}
          </div> : null}
        </div>
        <FooterLinks links={data} />
        <NewsletterForm />
        <FooterForm form={data} />
      </div>}
      <p className={"site-footer-copyright"}>
        {data && data.globalSets[0].legalCopy ?
          <small dangerouslySetInnerHTML={{ __html: data.globalSets[0].legalCopy }} />
          : <small>
            &copy; {year.getFullYear()} Legal Action Center. All rights reserved.
          <Link to={"/page/privacy-policy"} tile={"View our privacy policy"}>&nbsp;Privacy Policy.</Link>
          </small>
        }
      </p>
    </div>
  </footer>
}

const FooterDetails = ({ details }) => {
  const data = details.globalSets[0].footerDetails
  return data.map(dat => {
    const number = dat.phoneNumber.match(/\d/g)
    return <div key={dat.id}>
      <p className="site-footer-heading">{dat.officeName}</p>
      <p>{dat.streetAddress}</p>
      <p>{dat.cityStateZip}</p>
      <p>
        <a href={`tel:${number.join("")}`} title={`call us`}>
          {dat.phoneNumber}
        </a>
      </p>
      <p>{dat.faxNumber}</p>
    </div>
  })
}

const FooterLinks = ({ links }) => {
  const data = links.globalSets[0].linksList[0]

  return <div className="site-footer-section site-footer-links">
    {data.heading ? <p className="site-footer-heading">{data.heading}</p> : null}
    <ul className="reset">
      {data.children.map(link => {
        const external = !!link.linkUrl
        return <li key={link.id}>
          {external ?
            <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">{link.copy}</a>
            : <Link to={`/${link.page[0].uri}`} title={`go to ${link.page[0].title}`}>{link.copy}</Link>
          }
        </li>
      })}
    </ul>
    <div className="pad-top">
      <p className="site-footer-heading">Follow</p>
      <SocialLinks />
    </div>
  </div>
}

const SocialLinks = () => {
  return <ul className="flex-list">
    <li>
      <a className="svg-link" href={"https://twitter.com/lac_news"} title={"Go to LAC's Twitter"} target="_blank" rel="noopener noreferrer">
        <Twitter />
      </a>
    </li>
    <li>
      <a className="svg-link" href={"https://www.facebook.com/legalactioncenter"} title={"Go to LAC's Facebook"} target="_blank" rel="noopener noreferrer">
        <Facebook />
      </a>
    </li>
    <li>
      <a className="svg-link" href={"https://www.linkedin.com/company/legal-action-center/"} title={"Go to LAC's LinkedIn"} target="_blank" rel="noopener noreferrer">
        <Linkedin />
      </a>
    </li>
    <li>
      <a className="svg-link" href={"https://www.instagram.com/legalactioncenter/"} title={"Go to LAC's LinkedIn"} target="_blank" rel="noopener noreferrer">
        <Instagram />
      </a>
    </li>
    <li>
      <a className="svg-link" href={"https://www.youtube.com/@legalactioncenter"} title={"Go to LAC's youtube"} target="_blank" rel="noopener noreferrer">
        <Youtube />
      </a>
    </li>
  </ul>
}

const FooterForm = ({ form }) => {
  return <div className="site-footer-section site-footer-form">
    <div dangerouslySetInnerHTML={{ __html: form.globalSets[0].embed }} />
  </div>
}

export default Footer

Footer.serverFetch = [
  () => fetchData(query, "global-footer")
]
