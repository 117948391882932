import React from "react";

const SvgSearch = props => (
  <svg width={31} height={30} {...props}>
    <g transform="translate(2 2)" fill="none" fillRule="evenodd">
      <circle stroke="#041E26" strokeWidth={3.5} cx={10.5} cy={10.5} r={10.5} />
      <path
        d="M17.563 19.27a1.923 1.923 0 01-.018-2.701 1.88 1.88 0 012.675-.019l8.217 8.18c.744.741.752 1.95.018 2.701a1.88 1.88 0 01-2.675.019l-8.217-8.18z"
        fill="#041E26"
        fillRule="nonzero"
      />
    </g>
  </svg>
);

export default SvgSearch;
