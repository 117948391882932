import React from "react";

const SvgNewsAction = props => (
  <svg data-name="Layer 1" viewBox="0 0 326 217" {...props}>
    <path fill="#e9e9e6" d="M0 0h326v217H0z" />
    <path fill="#fff" d="M122.81 163.88l106.4-55.38-106.4-55.38v110.76z" />
    <path fill="#e85352" d="M102.71 87.58l40.19 20.92-40.19 20.92V87.58z" />
  </svg>
);

export default SvgNewsAction;
