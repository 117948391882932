import React from "react"
import {connect} from "react-redux";
import OutboundLink from "blocks/OutboundLink"
import Twitter from "svgr/Twitter"
import Facebook from "svgr/Facebook"
import Linkedin from "svgr/Linkedin"

const SocialShare = ({title,url,copy=""}) => {
  const fullUrl = `${process.env.PUBLIC_URL}/${url}`
  return <ul className="flex-list social-list">
    <li>
      <OutboundLink
        url={`https://twitter.com/intent/tweet?text=${title}%20${encodeURI(fullUrl)}`}
        className="svg-link"
        title={"Share this page on Twitter"}>
        <Twitter />
      </OutboundLink>
    </li>
    <li>
      <OutboundLink
        url={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(fullUrl)}`}
        className="svg-link"
        title={"Share this page on FaceBook"}>
        <Facebook />
      </OutboundLink>
    </li>
    <li>
      <OutboundLink
        url={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURI(fullUrl)}&title=${title}&summary=${copy}&source=`}
        className="svg-link"
        title={"Share this page on LinkedIn"}>
        <Linkedin />
      </OutboundLink>
    </li>
  </ul>
}

const mapStateToProps = state => ({
  data: state
})

export default connect(mapStateToProps,null)(SocialShare);
