import React from "react";

const SvgLegalDocLg = props => (
  <svg
    id="legal-doc-LG_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 372 372"
    {...props}
  >
    <defs>
      <style>{".legal-doc-LG_svg__cls-1{fill:#e9e9e6}"}</style>
    </defs>
    <path className="legal-doc-LG_svg__cls-1" d="M0 0h372v372H0z" />
    <path
      fill="#fff"
      d="M263.52 282.91H108.47V89.09h114.01l41.04 44.7v149.12z"
    />
    <path d="M182 114.21l-30.68 59h61.36z" fill="#e85352" />
    <path
      className="legal-doc-LG_svg__cls-1"
      d="M134.32 198.87h103.37v13.02H134.32zM134.32 250.65H186v13.02h-51.68zM134.32 224.76h103.37v13.02H134.32z"
    />
  </svg>
);

export default SvgLegalDocLg;
