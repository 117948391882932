const pageEntry = `
page: pageEntry {
  id
  uri
  title
  slug
}`

export const relatedTags = `
relatedTag {
  id
  slug
  title
  typeHandle
}
`

export const linkTo = `
linkTo {
  ...on linkTo_internalLink_BlockType {
    id
    typeHandle
    linkCta
    linkStyle
    ${pageEntry}
  }
  ...on linkTo_externalLink_BlockType {
    id
    typeHandle
    linkUrl
    linkCta
    linkTitle
    linkStyle
  }
}`


export const fileVars = `
  id
  url
  filename
  title
  path
  extension
`

export const imageAsset = `
...on images_Asset {
  ${fileVars}
  imageTransforms {
    src,
    srcset,
    srcWebp,
    srcsetWebp,
    maxSrcsetWidth,
    optimizedImageUrls,
    originalImageWidth,
    originalImageHeight
    optimizedWebPImageUrls,
    colorPalette,
    placeholderWidth,
    placeholderHeight,
    focalPoint
    placeholderImage,
    placeholderBox,
    placeholderSilhouette
  }
}`

const moduleChildBlock = `
  ...on moduleBuilder_block_BlockType {
    id
    typeHandle
    heading
    image {
      ${imageAsset}
    }
    body
    ${linkTo}
  }
`

export const modules = `
  id
  slug
  title
  typeHandle
  ...on module_formModule_Entry {
    color
    heading
    copy
    embed
  }
  ...on module_module_Entry {
    moduleBuilder {
      ...on moduleBuilder_simpleModule_BlockType {
        id
        typeHandle
        color
        children {
          ${moduleChildBlock}
        }
      }
      ...on moduleBuilder_imageModule_BlockType {
        id
        typeHandle
        imageAsBackground
        children {
          ${moduleChildBlock}
        }
      }
      ...on moduleBuilder_timeline_BlockType {
        id
        typeHandle
        heading
        children {
          ...on moduleBuilder_timelineEntry_BlockType {
            id
            heading
            date @formatDateTime(format: "M d, Y")
            image {
              ${imageAsset}
            }
            body
          }
        }
      }
      ...on moduleBuilder_gridModule_BlockType {
        id
        typeHandle
        heading
        children {
          ${moduleChildBlock}
        }
      }
      ...on moduleBuilder_storiesModule_BlockType {
        id
        typeHandle
        heading
        children {
          ...on moduleBuilder_storyType_BlockType {
            id
            typeHandle
            newsEntryType
          }
          ...on moduleBuilder_newsStoryType_BlockType {
            id
            typeHandle
            storyType
          }
        }
      }
    }
  }
`

const heroModule = `
heroModule {
  ...on heroModule_hero_BlockType {
    image {
      ${imageAsset}
    }
    heading
    copy
  }
}`

const linkToBlockType = `
  id
  typeHandle
  pageEntry {
    id
    title
    uri
  }
  hashLink
  heading
  explainer
  imageAsset  {
    ${imageAsset}
  }
  accentColor
  buttonCopy
`

let qaBlock = `
...on qabuilder_questionAnswer_BlockType {
  id
  typeHandle
  copy
  body
}`

const donateBlock = `
...on pageBuilder_donateBlock_BlockType {
  id
  typeHandle
  linkUrl
  color
  heading
  copy
}
`

export const pageBuilder = `
pageBuilder {
  ...on pageBuilder_linkModule_BlockType {
    id
    typeHandle
    internalLinkModule {
      ...on internalLinkModule_linkTo_BlockType {
        ${linkToBlockType}
      }
      ...on internalLinkModule_heading_BlockType {
        id
        sectionHeading
      }
    }
    children {
      ...on pageBuilder_linkTo_BlockType {
        id
        typeHandle
        copy
        body
        color
        image {
          ${imageAsset}
        }
        body
        ${linkTo}
      }
    }
  }
  ...on pageBuilder_image_BlockType {
    id
    typeHandle
    image {
      ${imageAsset}
    }
    color
  }
  ...on pageBuilder_splitList_BlockType {
    id
    typeHandle
    children {
      ...on pageBuilder_headingBlock_BlockType {
        id
        typeHandle
        heading
        copy: simpleBodyCopy
      }
      ...on pageBuilder_listBlock_BlockType {
        id
        typeHandle
        heading
        body
      }
    }
  }
  ...on pageBuilder_articleModule_BlockType {
    id
    typeHandle
    children {
      ...on pageBuilder_image_BlockType {
        id
        typeHandle
        image {
          ${imageAsset}
        }
        color
      }
      ...on pageBuilder_bodyCopy_BlockType {
        id
        typeHandle
        heading
        copy
      }
      ...on pageBuilder_bodyBlock_BlockType {
        id
        typeHandle
        body
      }
      ...on pageBuilder_statistic_BlockType {
        id
        typeHandle
        heading
        copy
      }
      ...on pageBuilder_embed_BlockType {
        id
        typeHandle
        embed
      }
      ${donateBlock}
    }
  }
  ...on pageBuilder_factBlock_BlockType {
    id
    typeHandle
    factBlock {
      ...on factBlock_fact_BlockType {
        id
        heading
        subheading
        copy
      }
    }
  }
  ...on pageBuilder_module_BlockType {
    id
    typeHandle
    modules {
      ${modules}
    }
  }
}`


export const homeQuery = `
query {
  entries(section:"home") {
    id
    slug
    title
    ...on home_homePage_Entry {
      homePageBuilder {
      ...on homePageBuilder_hero_BlockType {
          id
          typeHandle
          image {
            ${imageAsset}
          }
          heroHeading
      }
      ...on homePageBuilder_ctaLinks_BlockType {
          id
          typeHandle
          ${linkTo}
          videoButton {
            ...on videoButton_videoLink_BlockType {
              videoAsset {
                ${fileVars}
              }
              buttonCopy
            }
          }
        }
        ...on homePageBuilder_linkList_BlockType {
          id
          typeHandle
          listStyle
          internalLinkModule {
            ...on internalLinkModule_linkTo_BlockType {
              ${linkToBlockType}
            }
            ...on internalLinkModule_heading_BlockType {
              id
              typeHandle
              sectionHeading
            }
          }
        }
        ...on homePageBuilder_factBlock_BlockType {
          id
          typeHandle
          factBlock {
            ...on factBlock_fact_BlockType {
              id
              heading
              subheading
              copy
            }
          }
          ${linkTo}
        }
        ...on homePageBuilder_relatedContent_BlockType {
          id
          typeHandle
          children {
            ...on homePageBuilder_featuredEntry_BlockType {
              id
              typeHandle
              featuredContent {
                id
                title
                slug
                uri
                typeHandle
                ...on news_news_Entry {
                  newsTag {
                    id
                    title
                    slug
                  }
                  heading
                  image {
                    ${imageAsset}
                  }
                  date
                }
                ...on news_story_Entry {
                  storyType
                  heading
                  image {
                    ${imageAsset}
                  }
                  copy
                  date
                }
                ...on news_action_Entry {
                  heading
                  date
                  image {
                    ${imageAsset}
                  }
                }
              }
            }
          }
        }
        ...on homePageBuilder_module_BlockType {
          id
          typeHandle
          modules {
            ${modules}
          }
        }
      }
    }
  }
}`


export const aboutPageQuery = `
query($slug: [String]) {
  entries(section: "about", type: "page", slug: $slug, unique: true) {
    id
    slug
    title
    sectionHandle
    ...on about_page_Entry {
      ${heroModule}
      ${pageBuilder}
    }
  }
}`

export const donateQuery = `
query {
  entries(section: "donate") {
    id
    title
    typeHandle
    slug
    sectionHandle
    ...on donate_supportPage_Entry {
      heading
      copy
      image {
        ${imageAsset}
      }
      embed
      modules {
        ${modules}
      }
    }
  }
}`

export const fundraisingQuery = `
query {
  entries(section: "eventLanding") {
    id
    title
    typeHandle
    slug
    sectionHandle
    ...on eventLanding_eventLanding_Entry {
      ${heroModule}
      featuredEvent {
        id
        title
        slug
        typeHandle
        ...on events_events_Entry {
          image {
            ${imageAsset}
          }
          eventDate @formatDateTime
          copy
          linkUrl
        }
        ...on events_trainings_Entry {
          id
          title
          uri
          typeHandle
          heading
          copy
          image {
            ${imageAsset}
          }
          eventDate @formatDateTime
        }
      }
      modules {
        ${modules}
      }
    }
  }
}`

export const jobPosts = `
query($slug: [String]) {
  entries(section: "jobPosting", slug: $slug) {
    id
    title
    slug
    uri
    ...on jobPosting_jobPost_Entry {
      heading
      body
      longCopy
    }
  }
}`

export const teamPage = `
query($slug: [String]) {
  entries(section: "about", type: "team", slug: $slug, unique: true) {
    id
    title
    slug
    ...on about_team_Entry {
      heroModule {
        ...on heroModule_hero_BlockType {
          image {
            ${imageAsset}
          }
          heading
          copy
        }
      }
      ${pageBuilder}
      modules {
        ${modules}
      }
    }
  }
}`

export const reports = `query {
  entries(section: "report", orderBy: "dateUpdated desc") {
    id
    title
    uri
    ...on report_report_Entry {
      slug
      file {
        ${fileVars}
      }
      image {
        ${imageAsset}
      }
    }
  }
}
`
export const newsletterForm = `query{
  globalSets(handle: "newsletter") {
    ...on newsletter_GlobalSet {
      heading
      copy
      embed
    }
  }
}`

export const globalSearch = `
query($param:String) {
  entries(section: ["news","resource", "press", "majorProject", "work", "chapteredPage", "standardPage", "jobPosting", "people", "events", "plainTextPage"], search: $param, orderBy: "score") {
    ...on news_news_Entry {
      id
      title
      uri
      typeHandle
      heading
      date
      newsTag {
        title
      }
      heading
      image {
        ${imageAsset}
      }
      date
    }

    ...on news_story_Entry {
      id
      title
      uri
      typeHandle
      storyType
      heading
      image {
        ${imageAsset}
      }
      copy
      date
    }

    ...on news_action_Entry {
      id
      title
      uri
      typeHandle
      heading
      date
      image {
        ${imageAsset}
      }
    }

    ...on work_issueArea_Entry {
      id
      title
      uri
      typeHandle
      heading
      heroModule {
        ...on heroModule_hero_BlockType {
          image {
            ${imageAsset}
          }
        }
      }
    }

    ...on resource_resource_Entry {
      id
      title
      uri
      typeHandle
      heading
      date
      resourceTag {
        title
      }
      image {
        ${imageAsset}
      }
    }

    ...on resource_simpleResource_Entry {
      id
      title
      uri
      typeHandle
      date
      resourceTag {
        title
      }
      image {
        ${imageAsset}
      }
      staffMember {
        id
        title
        slug
        typeHandle
      }
      date
      resourceLink {
        ...on resourceLink_BlockType {
          externalLink
          fileAsset {
            ${fileVars}
          }
        }
      }
    }

    ...on press_press_Entry {
      id
      title
      uri
      typeHandle
      source: copy
      date
      linkUrl
      image {
        ${imageAsset}
      }
    }

    ...on majorProject_majorProject_Entry {
      id
      title
      uri
      typeHandle
      heading: copy
      image {
        ${imageAsset}
      }
    }

    ...on people_people_Entry {
      id
      title
      uri
      typeHandle
      heading: copy
      image {
        ${imageAsset}
      }
    }
    ...on people_boardMember_Entry {
      id
      title
      uri
      typeHandle
      heading
    }

    ...on events_events_Entry {
      id
      title
      uri
      typeHandle
      copy
      image {
        ${imageAsset}
      }
      eventDate @formatDateTime
      linkUrl
    }

    ...on events_trainings_Entry {
      id
      title
      uri
      typeHandle
      heading
      copy
      image {
        ${imageAsset}
      }
      eventDate @formatDateTime
    }

    ...on jobPosting_jobPost_Entry {
      id
      title
      uri
      typeHandle
      heading
    }

    ...on standardPage_standardPage_Entry {
      id
      title
      uri
      typeHandle
    }

    ...on chapteredPage_chapteredPage_Entry {
      id
      title
      uri
      typeHandle
    }

    ...on plainTextPage_plainTextPage_Entry {
      id
      title
      uri
      typeHandle
    }
  }
}`

export const plainPage = `query($slug:[String]){
  entries(section: "plainTextPage", slug: $slug) {
    id
    title
    slug
    uri
    sectionHandle
    ...on plainTextPage_plainTextPage_Entry {
      heading
      body
    }
  }
}`

export const writtenBy = `
query($id:QueryArgument,$relatedTo:[Int]) {
  entries(section: ["news", "resource", "press"], id: ["not", $id], relatedTo: $relatedTo) {
    id
    title
    uri
    sectionHandle
    typeHandle
    ...on news_news_Entry {
      title
      typeHandle
      newsTag {
        id
        title
        slug
      }
      heading
      image {
        ${imageAsset}
      }
      date
    }
    ...on news_story_Entry {
      typeHandle
      storyType
      heading
      image {
        ${imageAsset}
      }
      copy
      date
    }
    ...on news_action_Entry {
      typeHandle
      heading
      date
      image {
        ${imageAsset}
      }
    }

    ...on press_press_Entry {
      id
      title
      uri
      typeHandle
      source: copy
      date
      linkUrl
      image {
        ${imageAsset}
      }
    }

    ...on resource_resource_Entry {
      resourceTag {
        id
        slug
        title
      }
      heading
      date
      image {
        ${imageAsset}
      }
      file {
        ${fileVars}
      }
      staffMember {
        id
        slug
        typeHandle
        title
      }
      relatedTag {
        id
        slug
      }
    }
    ...on resource_simpleResource_Entry {
      date
      image {
        ${imageAsset}
      }
      resourceTag {
        id
        slug
        title
      }
      relatedTag {
        id
        slug
      }
      staffMember {
        id
        slug
        typeHandle
        title
      }
      resourceLink {
        ...on resourceLink_BlockType {
          externalLink
          fileAsset {
            ${fileVars}
          }
        }
      }
    }
  }
}`

export const defaultPage = `query($slug:[String]) {
  entries(section: ["standardPage","chapteredPage"], slug: $slug) {
    id
    slug
    title
    sectionHandle
    ...on standardPage_standardPage_Entry {
      ${heroModule}
      ${pageBuilder}
    }
    ...on chapteredPage_chapteredPage_Entry {
      qabuilder {
        ...on qabuilder_questionSection_BlockType {
          id
          typeHandle
          heading
          children {
            ...on qabuilder_questionSection_BlockType {
              id
              typeHandle
              heading
              children {
                ${qaBlock}
              }
            }
            ${qaBlock}
          }
        }
      }
    }
  }
}`

export const training = `query($slug:[String]) {
  entries(section: "events", type: "trainings", slug: $slug) {
    id
    title
    slug
    uri
    typeHandle
    ...on events_trainings_Entry {
      heading
      copy
      image {
        ${imageAsset}
      }
      body
      eventDate @formatDateTime
      linkUrl
    }
  }
}`

export const resourceData = `
id
title
uri
sectionHandle
typeHandle
...on resource_resource_Entry {
  resourceTag {
    id
    slug
    title
  }
  heading
  date
  image {
    ${imageAsset}
  }
  file {
    ${fileVars}
  }
  staffMember {
    id
    slug
    typeHandle
    title
  }
  relatedTag {
    id
    typeHandle
    slug
  }
}
...on resource_simpleResource_Entry {
  date
  image {
    ${imageAsset}
  }
  resourceTag {
    id
    slug
    title
  }
  relatedTag {
    id
    typeHandle
    slug
  }
  staffMember {
    id
    slug
    typeHandle
    title
  }
  resourceLink {
    ...on resourceLink_BlockType {
      externalLink
      fileAsset {
        ${fileVars}
      }
    }
  }
}`

export const manualResources = `
  manualResources: manualResourceSelection {
    ...on manualResourceSelection_resources_BlockType {
      resources {
        ${resourceData}
      }
    }
    ...on manualResourceSelection_noResources_BlockType {
      hideResources
    }
  }
`

export const redirect = `
query($uri:String) {
  retour(uri: $uri) {
    id
    enabled
    redirectSrcUrl
    redirectSrcUrlParsed
    redirectSrcMatch
    redirectMatchType
    redirectDestUrl
    redirectHttpCode
  }
}`

export const seo = `
query($uri:String) {
  seomatic(uri: $uri) {
    metaTitleContainer
    metaTagContainer
    metaLinkContainer
    metaScriptContainer
    metaJsonLdContainer
  }
}`
