import React from "react"
import { Link } from "react-router-dom"
import Image from "./Image"
import { truncateText } from "helpers"
import News from "svgr/News"
import NewsAction from "svgr/NewsAction"
import useBreakpoint from "useBreakpoint"
import Tag from "./Tag"
import "blocks/Cards.scss"
import OutboundLink from "./OutboundLink"

const NewsLink = ({ entry, children, className, title, ...rest }) => {
  return entry.typeHandle === "press" ? <OutboundLink url={entry.linkUrl} className={className} title={title}>
    {children}
  </OutboundLink> : <Link to={`/${entry.uri}`} className={className} title={title}>
      {children}
    </Link>
}

const NewsModule = ({ article, color = "white", featured = false }) => {
  const image = article.image && article.image[0]
  let typeLabel = article.storyType ? article.storyType : (article.newsTag ? article.newsTag[0].title : article.typeHandle)
  let mobile = useBreakpoint() !== "desktop"

  return featured ? <FeaturedModule article={article} type={typeLabel} /> : <NewsLink
    entry={article}
    title={`read ${article.title}`}
    className={`news-module card card-with-img img--back ${color}`}>
    <Tag tag={article} />
    {image ? <Image image={image} className="full-width" /> : <NewsSvg type={typeLabel} />}
    <div className="card-content news__content" style={{ backgroundColor: `var(--bg-${color})` }}>
      <div>
        <h3>{article.title}</h3>
        <span className="publish-date">{article.date}</span>
      </div>
      {article.heading && !mobile ? <p className="body-text">{truncateText(article.heading, 130)}</p> : null}
    </div>
  </NewsLink>
}

const FeaturedModule = ({ article, type }) => {
  const image = article.image && article.image[0]
  let mobile = useBreakpoint() === "mobile"
  let labelColor = type === "action" ? "red" : ""
  return <NewsLink
    entry={article}
    title={`read ${article.title}`}
    className={`news-module card news-module-double gray card-with-img ${labelColor}`}>
    <Tag tag={article} />
    {image ? <Image image={image} className="full-width" /> : <NewsSvg type={type} />}
    <div className={`card-content news__content ${image ? "" : "svg-wrap"}`}>
      <div>
        <h3>{article.title}</h3>
        <span className="publish-date">{article.date}</span>
      </div>
      {article.heading && !mobile ? <p className="body-text">{truncateText(article.heading, 300)}</p> : null}
    </div>
  </NewsLink>
}

const NewsSvg = ({ type }) => {
  return <figure className="svg-wrap">
    {type === "action" ? <NewsAction /> : <News />}
  </figure>
}

export default NewsModule
