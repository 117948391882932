export const storeData = (query, data) => ({
  type: "STORE_DATA",
  query,
  data
})

export const fetchData = (query, key, postURL) => {
  const endpoint = key ? key : query
  let fetchUrl = postURL ? postURL : process.env.REACT_APP_API

  let headers = typeof window !== "undefined" && window.location.search.match(/\btoken=([^&]+)/) ? {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "authorization",
    "X-Craft-Token": window.location.search.match(/\btoken=([^&]+)/)[1]
  } : {
      "Content-Type": "application/json"
    }

  return (dispatch, getState) => {
    return fetch(fetchUrl, {
      method: "POST",
      headers: headers,
      body: query
    })
      .then(response => response.json())
      .then(response => {
        // console.log(response, endpoint)
        dispatch(storeData(endpoint, response.data))
      })
      .catch(err => {
        console.log(query, err)
      })
  }
}
