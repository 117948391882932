export const priorityColor = {
  52: "green",
  62: "yellow",
  68: "blue"
}

export const workColor = {
  71: "blue",
  74: "yellow",
  77: "green",
  81: "gray",
  87: "red"
}
