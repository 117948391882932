import React from "react"
import { HashLink as Link } from "react-router-hash-link"
import ArticleLayout from "components/ArticleLayout"
import PageHeader from "blocks/PageHeader"
import BodyText from "blocks/BodyText"
import ScrollToTop from "blocks/ScrollToTop"

import "./Faq.scss"

const ChapteredPage = ({ page }) => {
  return <React.Fragment>
    <PageHeader className="center pad">
      <h1>{page.title}</h1>
    </PageHeader>
    <ScrollToTop />
    <ArticleLayout side="left">
      <div className="faq-nav">
        <Nav items={page.qabuilder} />
      </div>
      <div>
        <QuestionBlocks blocks={page.qabuilder} />
      </div>
    </ArticleLayout>
  </React.Fragment>
}

const QuestionBlocks = ({ blocks }) => {
  return <div>
    {blocks.map(block =>
      <div id={`question-${block.id}`} key={block.id} className="question">
        <h2>{block.heading}</h2>
        {block.children ? block.children.map(child => {
          return <div className="question-section" key={child.id} id={`question-${child.id}`} >
            <h3>{child.heading}</h3>
            <div className="question-block article">
              {child.children ? child.children.map(section => <div key={section.id}>
                <h4>{section.copy}</h4>
                <BodyText body={section.body} />
              </div>) : null}
            </div>
          </div>
        }) : null}
      </div>
    )}
  </div>
}

const Nav = ({ items }) => {
  return <ul className="faq-nav-list sticky">
    {items.map(list => <li key={list.id}>
      <Link smooth to={`#question-${list.id}`} className="section-header left" title={`scroll to ${list.heading}`}>{list.heading}</Link>
      {list.children ?
        <ul>{list.children.map(child => {
          if (child.typeHandle === "questionSection") {
            return <li key={child.id} className="section-subheader">
              <Link smooth to={`#question-${child.id}`} title={`scroll to ${child.heading}`}>{child.heading}</Link>
            </li>
          } else return null
        })}
        </ul>
        : null}
    </li>)}
  </ul>
}

export default ChapteredPage
