import React from "react"
import { useParams, Link } from "react-router-dom"
import { useFetchStore, useFetchStoreVars } from "Fetch"
import SectionHeader from "blocks/SectionHeader"
import Frame from "blocks/Frame"
import BodyText from "blocks/BodyText"
import PageHeader from "blocks/PageHeader"
import { ImgBg } from "blocks/Image"
import NewsModule from "blocks/NewsModule"
import Loading from "blocks/Loading"
import { ResourceCard } from "components/Resources/Resources"
import { sortData } from "blocks/Accordion"
import PageBuilder from "components/PageBuilder"

import People from "blocks/People"
import Hero from "blocks/Hero"
import { people, person } from "blocks/People/query"
import {
  teamPage as pageQuery,
  writtenBy
} from "queries"

import ArticleLayout from "components/ArticleLayout"

const Team = () => {
  const params = useParams()
  const allTeam = useFetchStore(people, "team")
  const query = params.person ? person : pageQuery

  const data = useFetchStoreVars(query, { slug: params.person ? params.person : "our-team" }, `people-${params.person}`)

  if (params.person) {
    return (allTeam && data) ? <Individual person={data.entries[0]} others={allTeam.entries} /> : <Loading />
  } else {
    return (allTeam && data) ? <Page content={allTeam.entries} data={data.entries} /> : <Loading />
  }
}

const Individual = ({ person, others }) => {
  return person.typeHandle === "people" ?
    <Staff person={person} />
    : <Board person={person} />
}

const Crumb = () => <ul className="breadcrumbs red">
  <li><Link title="" to={"/about/our-team"}><span>Our Team</span></Link></li>
</ul>

const Staff = ({ person, others }) => {
  return <section>
    <div className="pad-top pad-bottom">
      <ArticleLayout side="left">
        <div>
          <Frame color={"gray"}>
            {person.image[0] ?
              <ImgBg height={100} image={person.image[0]} />
              : null}
          </Frame>
        </div>
        <div>
          <PageHeader className="">
            <Crumb />
            <h1>{person.title}</h1>
            <h2>{person.copy}</h2>
            <h2>{person.email}</h2>
          </PageHeader>
          <BodyText body={person.body} />
        </div>
      </ArticleLayout>
    </div>
    <ArticlesWritten person={person} />
  </section>
}

const Board = ({ person, others }) => {
  return <section>
    <div className="max-width pad-top pad-bottom grid grid--50">
      <div className="grid-block">
        <PageHeader>
          <Crumb />
          <h1>{person.title}</h1>
          <h2>{person.copy}</h2>
          <h2>{person.email}</h2>
        </PageHeader>
        <BodyText body={person.body} />
      </div>
      <div />
    </div>
    <ArticlesWritten person={person} />
  </section>
}

const Page = ({ content, data }) => {
  const entry = data[0]
  const hero = entry.heroModule[0]
  const people = content.reduce((res, pers) => {
    const type = pers.typeHandle
    const data = res[type] || { data: [] }
    data.data.push(pers)
    res[type] = data
    return res
  }, {})

  let sortStaff = people.people.data.sort((a, b) => {
    let pers1 = a.title.split(' ')[a.title.split(' ').length - 1]
    let pers2 = b.title.split(' ')[b.title.split(' ').length - 1]
    return pers1 < pers2 ? -1 : 1
  })

  return <section>
    <Hero
      image={hero.image}
      heading={hero.heading}
      copy={hero.copy}
      styleType={"default"}
    />
    <div className="max-width pad-top pad-bottom">
      <SectionHeader className="large" heading={"Staff"} />
      <People people={sortStaff} />
    </div>
    {people.boardMember ?
      <div className="max-width pad-top pad-bottom">
        <SectionHeader className="large underline" heading={"Board Members"} />
        <People people={people.boardMember.data} />
      </div>
      : null
    }
    <PageBuilder data={data[0].pageBuilder} slug={data[0].slug} />
  </section>
}

const ArticlesWritten = ({ person }) => {
  const articles = useFetchStoreVars(writtenBy, { id: null, relatedTo: person.id }, `articles-${person.id}`)
  let sorted = articles ? sortData(articles.entries) : null

  return articles && articles.entries.length ? <div className="pad-lg bg--light-gray">
    <SectionHeader heading={`Work by ${person.title}`} />
    <ul className="grid grid--33">
      {sorted.map(article => {
        return ["news", "press"].includes(article.sectionHandle) ?
          <NewsModule key={article.id} article={article} />
          : <ResourceCard key={article.id} resource={article} />
      })}
    </ul>
  </div> : null
}

export default Team
