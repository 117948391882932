import React from "react";

const SvgYT = props => (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
    viewBox="0 145 612 485" enableBackground="new 0 145 612 485" {...props}>
    <path d="M389.4,370.8c9.2,4.7,12.9,16,8.1,25.3c-1.8,3.5-4.6,6.4-8.1,8.1l-58.6,30.2l-64.2,32.9c-9.2,4.7-20.5,1.1-25.2-8.2
 c-1.3-2.6-2-5.5-2.1-8.4V324.3c0-10.4,8.4-18.8,18.8-18.8c3,0,5.9,0.7,8.5,2.1l64.2,33L389.4,370.8z"/>
    <path d="M489.5,618.6h-369c-63.1,0-114.4-55.3-114.4-123.2V279.6c0-67.9,51.3-123.2,114.4-123.2h369c63.1,0,114.4,55.3,114.4,123.2
 v215.8C603.9,563.3,552.6,618.6,489.5,618.6z M120.5,196.4c-41,0-74.4,37.3-74.4,83.2v215.8c0,45.9,33.4,83.2,74.4,83.2h369
 c41,0,74.4-37.3,74.4-83.2V279.6c0-45.9-33.4-83.2-74.4-83.2H120.5z"/>
  </svg>
);

export default SvgYT;