import React from "react";

const SvgLoader = props => (
  <svg viewBox="0 0 145 142" {...props}>
    <path
      d="M100 78.8H44.9c9.2-17.6 18.3-35.1 27.5-52.9 9.3 17.7 18.4 35.2 27.6 52.9z"
      fill="#c52f2c"
    />
    <path
      d="M118.2 113.8H84c-.8 0-1.2-.2-1.6-.9-3-5.8-6-11.6-9-17.3-.3-.5-.6-1-.9-1.7-1.5 2.8-2.9 5.5-4.2 8.1-1.9 3.7-3.8 7.4-5.8 11-.2.4-.8.8-1.2.8h-34c-.1 0-.2 0-.5-.1l.3-.9c3.2-6.1 6.4-12.2 9.6-18.4.3-.6.6-.8 1.3-.8h69.1c.4 0 1 .3 1.1.6l9.9 18.9c0 .3 0 .4.1.7z"
      fill="#041e26"
    />
  </svg>
);

export default SvgLoader;
