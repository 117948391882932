import React, { useState } from "react"
import { ExternalLink as Button } from "blocks/Button"

export default ({ embed }) => {
  const [recurring, setRecurring] = useState(null)
  const [amount, setAmount] = useState(null)

  return !embed ? <div id="donate-form" className="pad-top">
    <div className="donate-type">
      <button className={`button blackBorder greenBorder ${recurring === false ? "active" : ""}`} onClick={() => {
        setRecurring(false)
      }}>Donate Once</button>
      <button className={`button blackBorder greenBorder ${recurring === true ? "active" : ""}`} onClick={() => {
        setRecurring(true)
      }}>Donate Monthly</button>
    </div>
    <div className="buttons donate-amount">
      {[500, 250, 100, 50].map(amt => {
        return <Button
          key={amt}
          linkUrl={`https://secure.givelively.org/donate/legal-action-center?override_amount=${amt}&recurring=${recurring}`}
          linkCta={`$${amt}`}
          linkStyle={"blackBorder"}
          linkTitle={`Donate $${amt} to LAC via Classy`}
        />
      })}
    </div>
    <div>
      <input type="number" name="donate-amount" placeholder="Custom Amount" onChange={(e) => {
        setAmount(e.currentTarget.value)
      }} className="button blackBorder" />
    </div>
    <Button
      linkUrl={`https://secure.givelively.org/donate/legal-action-center?override_amount=${amount}&recurring=${recurring}`}
      linkCta={`Donate`}
      linkStyle={"defaultRed"}
      linkTitle={`Donate $${amount} to LAC via Classy`}
    />
  </div> : <div className="pad-top" dangerouslySetInnerHTML={{ __html: embed }} />
}
