import React from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation
} from "react-router-dom";
import ScrollMemory from "react-router-scroll-memory";
import ReactGA from "react-ga";
import Nav from "components/Nav"
import Footer from "components/Footer"
import Page from "components/Page"
import Home from "components/Home"
import Resources from "components/Resources/Resources"
import Resource from "components/Resources/Resource"
import Reports from "components/Reports"
import News from "components/News"
import Press from "components/Press"
import Search from "components/Search"
import WorkPage from "components/Work/WorkPage"
import Priority from "components/Work/Priority"
import MajorProject from "components/Work/MajorProject"
import WorkAction from "components/Work/WorkAction"
import Issue from "components/Work/Issue"
import Team from "components/Team"
import Join from "components/Join"
import NoPage from "components/NoPage"
import Training from "components/Training"
import PlainTextPage from "components/PlainTextPage"
import GoogleTranslate from "components/GoogleTranslate"
import Seo from "components/Seo"
import { redirect } from "queries"
import { useFetchStoreVars } from "Fetch"

import "./App.scss";

ReactGA.initialize("UA-7237718-1");

const App = () => {
  return <div className={typeof window !== "undefined" && !!window.document.documentMode ? "is-ie" : ""}>
    <ScrollMemory />
    <GoogleTranslate />
    <Nav />
    <Main />
  </div>
}

export const Main = () => {
  const location = useLocation()
  const redirects = useFetchStoreVars(redirect, { uri: location.pathname }, `redirect-${location.pathname}`)

  return <main id="main">
    <Seo slug={location.pathname} />

    <Switch>
      {/* REDIRECT HANDLER */}
      {redirects && !!redirects.retour ?
        <Redirect from={redirects.retour.redirectSrcUrl} to={redirects.retour.redirectDestUrl} status={redirects.retour.redirectHttpCode} />
        : null}

      {/* RESOURCES */}
      <Route path="/resources" component={Resources} />
      <Route path="/resource/:slug" component={Resource} />

      {/* NEWS */}
      <Route path="/news/:slug" component={News} />
      <Route path="/news" component={Press} />

      {/* WORK PAGES*/}
      <Route path="/major-project/:slug" component={MajorProject} />
      <Route path="/work/priorities/:priority/:slug" component={Issue} />
      <Route path="/work/priorities/:slug" component={Priority} />
      <Route path="/work/what-we-do/:slug" component={WorkAction} />
      <Route path="/work/events/:slug" component={Training} />
      <Route path="/work" render={({ match: { url } }) => (
        <Route path={`${url}/:slug`} component={WorkPage} />
      )} />

      {/* ABOUT PAGES*/}
      <Route path={"/about/our-team/:person"} component={Team} />
      <Route path={"/about/our-team"} component={Team} />

      <Route path={"/about/join-our-team/:job"} component={Join} />
      <Route path={"/about/join-our-team"} component={Join} />

      <Route path={"/about/annual-reports"} component={Reports} />
      <Route path={"/about/:slug"} component={Page} />

      <Route path="/search" component={Search} />

      <Route path="/page/:slug" component={PlainTextPage} />

      <Route exact path="/not-found" component={NoPage} />
      <Route exact path="/" component={Home} />
      <Route path="/:slug?" component={Page} />
    </Switch>
    <Footer />
  </main>
}

export default App;

