import React from "react"
import ClientResourceLg from "svgr/resources/ClientResourceLg"
import LegalDocLg from "svgr/resources/LegalDocLg"
import PresentationLg from "svgr/resources/PresentationLg"
import ReportLg from "svgr/resources/ReportLg"
import TemplatesLg from "svgr/resources/TemplatesLg"
import ToolkitLg from "svgr/resources/ToolkitLg"

export default ({tag}) => {
  switch (tag) {
    case "Client Resource":
      return <ClientResourceLg />
    case "Legal Case":
      return <LegalDocLg />
    case "Presentation":
      return <PresentationLg />
    case "Report":
      return <ReportLg />
    case "Samples & Templates":
      return <TemplatesLg />
    case "Toolkit":
      return <ToolkitLg />
    default:
      return <ReportLg />
  }
}
