import React from "react"
import "./PageHeader.scss"

const PageHeader = ({className="", color=false, children}) => {
  const style = color ? {
    backgroundColor: `var(--bg-${color})`
  } : {}

  return <header className={`page-header ${className}`} style={style}>
    {children}
  </header>
}

export default PageHeader
