import React from "react";

const SvgToolkitLg = props => (
  <svg data-name="Layer 1" viewBox="0 0 372 372" {...props}>
    <path fill="#e9e9e6" d="M0 0h372v372H0z" />
    <path
      d="M282.2 191.66v-50.93h-50.95a40.51 40.51 0 00-40.45-39.61h-8.49a41.63 41.63 0 00-41.53 39.61h-51v50.93zm-192.4 11v68.22h192.4v-68.22zm92.51-90.22h8.49a29.18 29.18 0 0129.13 28.29h-67.81a30.29 30.29 0 0130.19-28.29z"
      fill="#fff"
    />
    <path d="M186 243.62L223.25 172h-74.5z" fill="#e85352" />
  </svg>
);

export default SvgToolkitLg;
