import React, {useRef,useLayoutEffect,useState} from "react"
import PageHeader from "blocks/PageHeader"
import Breadcrumbs from "blocks/Breadcrumbs"
import Image from "blocks/Image"
import useBreakpoint from "useBreakpoint"
import "./Hero.scss"

// TODO alt text
const Hero = ({image,heading,copy,styleType,children}) => {
  const breakpoint = useBreakpoint() === "desktop"
  const heroRef = useRef(null)
  const [offset,setOffset] = useState()
  const img = image[0]

  useLayoutEffect(() => {
    if (breakpoint && heroRef) {
      setOffset(heroRef.current ? `${heroRef.current.getBoundingClientRect().height / -2}px` : "-10%")
    } else {
      setOffset(null)
    }
  }, [breakpoint])

  return <div className={`hero hero--${styleType}${!img ? " no-img" : ""}`}>
    {img ?
      <figure className={"hero-image"}>
        {img.filename.match(/(.mov)/) ?
          <video autoPlay loop muted playsInline><source src={img.url} /></video>
        : <Image image={img} />}
      </figure>
    : null}
    <div className={"hero-content"} ref={heroRef} style={offset && img ? {marginBottom: offset} : {}}>
      <PageHeader className={"center"}>
        <h1>{heading}</h1>
      </PageHeader>
      {children}
    </div>
    {copy ? <div className="hero-subheading" dangerouslySetInnerHTML={{ __html: copy }} /> : null}
  </div>
}

export const WorkHero = ({title,image,color}) => {
  return <div className={`hero hero--work ${color}`}>
    <figure className="pad-lg">
      {image ? <Image image={image} /> : null}
    </figure>
    <PageHeader className="pad center">
      <h1>{title}</h1>
    </PageHeader>
  </div>
}

export const DetailHero = ({title,heading,image,color,className,breadcrumb}) => {
  return <div className={`hero hero--lighten bg-${color}`}>
    <div className={`hero hero--detail max-width pad-top pad-bottom ${color} ${className}`}>
      <PageHeader className={"lift"}>
        {breadcrumb ? <Breadcrumbs links={breadcrumb} /> : null}
        <h1>{title}</h1>
        <h2 dangerouslySetInnerHTML={{ __html: heading }} />
      </PageHeader>
        {image ? <Image image={image} /> : <figure/>}
    </div>
  </div>
}

export const HalfImageHero = ({image,title,copy,className}) => {
  return <div className={`hero hero--half ${className}`}>
    <Image image={image} />
    <div className="black">
      <PageHeader className={copy ? "lift" : ""}>
        <h1 style={{fontSize: "var(--size-h1-sm)"}}>{title}</h1>
        {copy ? <h2
          style={{fontSize: "var(--size-h2)"}}
          dangerouslySetInnerHTML={{ __html: copy }} />
          : null}
      </PageHeader>
    </div>
  </div>
}

export default Hero
