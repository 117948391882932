import React from "react";

const SvgClientResourceLg = props => (
  <svg data-name="Layer 1" viewBox="0 0 372 372" {...props}>
    <path fill="#e9e9e6" d="M0 0h372v372H0z" />
    <path
      d="M186 286.26c21.92-42.13 43.5-83.63 65.29-125.52H120.71z"
      fill="#e85352"
    />
    <circle cx={187.84} cy={111.88} r={26.14} fill="#fff" />
  </svg>
);

export default SvgClientResourceLg;
