import React from "react"
import { Link } from "react-router-dom";

export default ({ links }) => {
  return <ul className="breadcrumbs">
    {Object.keys(links).map(link => {
      let name = link.replace(/-/g, " ")
      return <li key={link}>
        {links[link] ? <Link to={`${links[link]}`} title={`go back to ${name}`}>
          <span>{name}</span>
        </Link> : <span>{name}</span>}
      </li>
    })}
  </ul>
}
