import { imageAsset, modules } from "queries"

export const allNews = `
query($id:QueryArgument, $relatedTo:[Int], $section:[String], $type:[String]) {
  entries(section: $section, type: $type, id: ["not", $id], relatedTo: $relatedTo, orderBy: "date") {
    ...on news_action_Entry {
      id
      typeHandle
      title
      uri
      heading
      date
      image {
        ${imageAsset}
      }
    }
    ...on news_news_Entry {
      id
      typeHandle
      title
      uri
      newsTag {
        id
        title
        slug
      }
      heading
      date
      image {
        ${imageAsset}
      }
    }
    ...on press_press_Entry {
      id
      typeHandle
      title
      uri
      source: copy
      date
      linkUrl
      image {
        ${imageAsset}
      }
    }
  }
}`

export const featuredAction = `query {
  entries(section: "news", type: "action", limit: 1) {
    id
    title
    uri
    ...on news_action_Entry {
      typeHandle
      heading
      date
      image {
        ${imageAsset}
      }
    }
  }
}`

export const press = `
query($limit:Int, $offset:Int, $relatedTo:[Int]) {
  entries(section: "press", limit: $limit, offset: $offset, relatedTo: $relatedTo) {
    id
    title
    uri
    typeHandle
    ...on press_press_Entry {
      source: copy
      date
      linkUrl
      relatedTag {
        id
        slug
      }
    }
  }
}`

export const pressPage = `query {
  entries(section: "newsIndex") {
    id
    title
    uri
    ...on newsIndex_pressIndex_Entry {
      heading
      slug
      modules {
        ${modules}
      }
      featuredContent {
        id
        title
        slug
        uri
        typeHandle
        ...on news_news_Entry {
          newsTag {
            id
            title
            slug
          }
          heading
          image {
            ${imageAsset}
          }
          date
        }
        ...on news_story_Entry {
          storyType
          heading
          image {
            ${imageAsset}
          }
          copy
          date
        }
        ...on news_action_Entry {
          heading
          date
          image {
            ${imageAsset}
          }
        }
      }
    }
  }
}`
