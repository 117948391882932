import React, {useState} from "react"
import {Link,useLocation} from "react-router-dom";
import {useFetchStore} from "Fetch"
import PageTitle from "blocks/PageTitle"
import Tag from "blocks/Tag"
import Image from "blocks/Image"
import Accordion from "blocks/Accordion"
import ResourceIcon from "blocks/ResourceIcon"
import ScrollToTop from "blocks/ScrollToTop"
import FilterNav from "blocks/FilterNav"
import OutboundLink from "blocks/OutboundLink"
import {
  allResources,
  indexPage
} from "./query"
import "blocks/Cards.scss"
import "./Resources.scss"

const Resources = () => {
  const pageData = useFetchStore(indexPage, "resources-index")
  let { search } = useLocation()
  let params = search.substr(1).split("&")
  let filterParams = search ? params[1].split("filters=")[1] : null
  let issueParams = search ? params[2].split("filter-issue=")[1] : null

  const [filters,setFilters] = useState({
    type: filterParams ? filterParams.split(",") : [],
    issueArea: issueParams ? issueParams.split(",") : [],
    date: null
  })

  let title = (pageData && pageData.entries[0].heading) || "Resources"

  return <section>
    <header className="pad-top pad-bottom max-width">
      <PageTitle heading={title} />
    </header>
    <FilterNav element="resourceType" filters={filters} setFilters={setFilters} />
    <div style={{minHeight: "calc(100vh - var(--navHeight))"}}>
      <ScrollToTop />
      <Accordion
        query={allResources}
        params={{relatedTo: filters.issueArea}}
        route={filters.issueArea.length ? `resources-${filters.issueArea}` : "resources"}
        limit={6}
        filter={{date: filters.date, type: filters.type}}
        buttonClass="center pad-bottom max-width"
        sort={true}>
        {(data) => {
          if (!data) return <p>Loading...</p>

          return <article className="max-width pad-top pad-bottom">
            <ResourceList data={data} />
          </article>
        }}
      </Accordion>
    </div>
  </section>
}

const ResourceList = ({data}) => {
  return data.length ? <ul className="grid grid--33 pad-bottom">
    {data.map(resource => {
      return <li key={`resource_${resource.id}`}>
        <ResourceCard resource={resource} />
      </li>
    })}
  </ul> : <div className="pad-top pad-bottom max-width article"><h2 className="center max-width">No results.</h2></div>
}

export const ResourceCard = ({resource}) => {
  const authors = resource.staffMember.length ? resource.staffMember : null
  const tag = resource.resourceTag[0] ? resource.resourceTag[0].title : "Resource"

  return <div className="card resource-card card-no-img">
    {resource.typeHandle === "resource" ?
      <Link className="reset resource-card-link" to={`/${resource.uri}`}>
        <Tag tag={resource} />
        {resource.image[0] ?
          <Image className="resource-image-pad" image={resource.image[0]} /> :
          <figure className="resource-image resource-svg"><ResourceIcon tag={tag} /></figure>
        }
        <div className="pad">
          <h3>{resource.title}</h3>
          <div>
            {authors ? authors.map((author, i) =>
              <span className="publish-date" key={`author_${author.id}`}>{`${i > 0 ? ", " : ""}${author.title}`}</span>)
            : null}
            <p className="publish-date">{resource.date}</p>
          </div>
        </div>
      </Link>
    : <OutboundLink
        url={resource.resourceLink[0].externalLink ? resource.resourceLink[0].externalLink : (resource.resourceLink[0].fileAsset[0] ? resource.resourceLink[0].fileAsset[0].url : "#")}
        title={`open resource ${resource.title}`}
        className="reset resource-card-link"
      >
      <Tag tag={resource} />
      {resource.image[0] ?
        <Image className="resource-image-pad" image={resource.image[0]} /> :
        <figure className="resource-image resource-svg"><ResourceIcon tag={tag} /></figure>
      }
      <div className="pad">
        <h3>{resource.title}</h3>
        <div>
          {authors ? authors.map((author, i) =>
            <span className="publish-date" key={`author_${author.id}`}>{`${i > 0 ? ", " : ""}${author.title}`}</span>)
          : null}
          <p className="publish-date">{resource.date}</p>
        </div>
      </div>
    </OutboundLink>
    }
  </div>
}

export default Resources
