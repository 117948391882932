import React from "react"
import { useParams, Link } from "react-router-dom"
import { useFetchStoreVars, useFetchStore } from "Fetch"
import PageHeader from "blocks/PageHeader"
import SectionHeader from "blocks/SectionHeader"
import Loading from "blocks/Loading"
import Module from "components/Module"
import { BigLinkModule, GridLinkModule } from "blocks/LinkList"
import { priorityColor, workColor } from "colors"
import checkAnchor from "checkAnchor"
import {
  pageQuery,
  priorities,
  workActions,
  majorProjects
} from "./query"

const WorkPage = () => {
  let { slug } = useParams()
  const data = useFetchStoreVars(pageQuery, { slug: slug }, `work-${slug}`)
  const query = slug === 'priorities' ? priorities : workActions
  checkAnchor()

  return data ? <section>
    <Head data={data.entries[0]} />
    <Body query={query} slug={slug} />
    {data.entries[0] && data.entries[0].modules.map(mod => <Module key={mod.id} module={mod} />)}
  </section> : <Loading />
}

const Head = ({ data }) => {
  return <PageHeader className={"center lift bg--gray"} color={"gray"}>
    <h1>{data.title}</h1>
    <h2>{data.heading}</h2>
  </PageHeader>
}

const Body = ({ query, slug }) => {
  const data = useFetchStore(query, slug)
  return data ? <div className={"link-list--big"}>
    {data.entries.map(entry => {
      const accentColor = priorityColor[entry.id] ? priorityColor[entry.id] : workColor[entry.id]
      return <BigLinkModule
        page={entry}
        heading={entry.title}
        explainer={entry.copy ? entry.copy : entry.heading}
        buttonCopy={"See More"}
        accentColor={accentColor}
        image={entry.image[0]}
        className={entry.typeHandle !== "priority" ? "center-mod" : "square-img"}
        key={`${entry.typeHandle}_${entry.id}`}>
        {entry.typeHandle === "priority" ?
          <div className="link-module__tags">
            <h4>Issue Areas</h4>
            {entry.children.map(child =>
              <Link key={child.id} to={`/${child.uri}`} className="button-xs button-color" style={{ backgroundColor: `var(--bg-${accentColor})` }}>
                {child.title}
              </Link>
            )}
          </div> : null
        }
      </BigLinkModule>
    })}
  </div> : null
}

export const MajorProjects = () => {
  const data = useFetchStore(majorProjects, "major-projects")
  return data ? <section className="max-width pad-bottom">
    <SectionHeader heading={"Major Projects"} />
    <div className="flex-list">
      {data.entries.map(entry => {
        return <GridLinkModule
          page={entry}
          heading={entry.title}
          bgColor={"light-gray"}
          position={"back"}
          image={entry.image[0]}
          key={`${entry.typeHandle}_${entry.id}`}
        />
      })}
    </div>
  </section> : null
}

export default WorkPage
