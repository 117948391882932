import React from "react";

const SvgTemplatesLg = props => (
  <svg
    id="templates-LG_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 372 372"
    {...props}
  >
    <defs>
      <style>{".templates-LG_svg__cls-2{fill:#fff}"}</style>
    </defs>
    <path fill="#e9e9e6" d="M0 0h372v372H0z" />
    <path
      className="templates-LG_svg__cls-2"
      d="M219.69 80.92H102v159.85h117.69V80.92z"
    />
    <path
      className="templates-LG_svg__cls-2"
      d="M235.83 108.05v148.87H127.08v34.16H270V108.05h-34.17z"
    />
    <path
      d="M196.88 195.49c-12-23.14-23.95-46.06-36-69.29-12.09 23.26-24 46.16-36 69.29z"
      fill="#e85352"
    />
  </svg>
);

export default SvgTemplatesLg;
