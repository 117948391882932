import React from "react";

const SvgLinkedin = props => (
  <svg viewBox="8 -6 24 24" {...props}>
    <path
      fill="#041E26"
      d="M29.6-6H10.4C9.1-6 8-4.9 8-3.6v19.2c0 1.3 1.1 2.4 2.4 2.4h19.2c1.3 0 2.4-1.1 2.4-2.4V-3.6C32-4.9 30.9-6 29.6-6zM15 16h-4V5h4v11zM14 3c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm14 13h-3.7V9.7c0-1-.9-1.8-1.8-1.8s-1.8.8-1.8 1.8V16H17V5.2h3.7v1.4c.6-1 2-1.7 3.1-1.7C26 5 28 6.9 28 9.2V16z"
    />
  </svg>
);

export default SvgLinkedin;
