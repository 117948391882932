export const query = `query {
  globalSets(handle: "footer") {
    ...on footer_GlobalSet {
      id
      footerDetails {
        ...on footerDetails_BlockType {
          id
          officeName
          streetAddress
          cityStateZip
          phoneNumber
          faxNumber
        }
      }
      linksList {
        ...on linksList_linkSection_BlockType {
          id
          typeHandle
          heading
          children {
            ...on linksList_linkTo_BlockType {
              id
              copy
              page {
                id
                title
                uri
              }
              linkUrl
            }
          }
        }
      }
      partnerLogo {
        ...on partnerLogo_partner_BlockType {
          id
          typeHandle
          logo {
            id
            title
            url
          }
          linkUrl
        }
      }
      embed
      legalCopy
    }
  }
}
`
