import React, {useState} from "react"
import {useLocation,Link} from "react-router-dom"
import ReactPaginate from "react-paginate"
import {useFetchStoreVars} from "Fetch"
import {truncateText,formatDateTime} from "helpers"
import ResourceIcon from "blocks/ResourceIcon"
import PageHeader from "blocks/PageHeader"
import Loading from "blocks/Loading"
import {globalSearch} from "queries"
import Tag from "blocks/Tag"
import Image, {ImgBg} from "blocks/Image"
import OutboundLink from "blocks/OutboundLink"
import LegalDocLg from "svgr/resources/LegalDocLg"

export default () => {
  let { search } = useLocation()
  let query = search ? decodeURI(search.split('?query=')[1]) : ""

  const results = useFetchStoreVars(globalSearch, { param: query }, `search-${query}`)

  return <section>
    <PageHeader className="pad-lg center">
      <h1>Search Results</h1>
    </PageHeader>
    {results ? <Pagination data={results} query={query} /> : <Loading />}
  </section>
}

const Pagination = ({data, query}) => {
  let results = data.entries.filter(value => Object.keys(value).length !== 0)
  let total = results.length
  const [offset,setOffset] = useState(0)
  const limit = 8
  let pages = Math.ceil(total / limit)
  let inView = offset+limit > total ? total : offset+limit

  let entries = results.slice(offset, inView)

  return <article className="pad-top pad-bottom max-width">
    {total < 1 ? <p className="center body-text" style={{maxWidth: 900, margin: "0 auto"}}>No results for the search: "{query}"</p>
      : <div style={{maxWidth: 900, margin: "0 auto"}}>
        <p className="body-text">Showing {offset === 0 ? 1 : offset} - {inView} of {total} results for the search: "{query}"</p>
        <div>
          <SearchResults results={entries} />
        </div>
        <ReactPaginate
          previousLabel={null}
          nextLabel={null}
          breakLabel={"..."}
          breakClassName={"button button-xs blackBorder"}
          pageCount={pages}
          pageRangeDisplayed={limit}
          pageClassName={"button button-xs blackBorder"}
          marginPagesDisplayed={1}
          onPageChange={(data)=> {
            setOffset(data.selected*limit)
            window.scrollTo(0, 0)
          }}
          containerClassName={"pagination max-width pad-lg"}
          activeLinkClassName={"active"}
          activeClassName={"active"}
        />
      </div>
    }
  </article>
}

const SearchResults = ({results}) => {
  return results.map(entry => {
    let external = ["press", "simpleResource", "events"].indexOf(entry.typeHandle) > -1
    return external ? <OutboundLink
      key={`${entry.typeHandle}_${entry.id}`}
      url={["press", "events"].indexOf(entry.typeHandle) > -1 ? entry.linkUrl : (entry.resourceLink[0].externalLink ? entry.resourceLink[0].externalLink : (entry.resourceLink[0].fileAsset[0] ? entry.resourceLink[0].fileAsset[0].url : "#"))}
      title={`View article on ${entry.source}`}
      className={`news-module search-results-card card`}>
        <SearchCard entry={entry} />
      </OutboundLink> : <Link
      key={`${entry.typeHandle}_${entry.id}`}
      to={`/${entry.uri}`}
      title={`read ${entry.title}`}
      className={`news-module search-results-card card`}>
        <SearchCard entry={entry} />
      </Link>
  })
}

const CardImg = ({result, image}) => {
  const type = result.typeHandle
  if ((type === "resource" || type === "simpleResource") && image) {
    return <Image className="resource-image-pad" image={image} />
  } else if ((type === "resource" || type === "simpleResource") && !image) {
    return <figure className="resource-image resource-svg"><ResourceIcon tag={result.resourceTag[0].title} /></figure>
  } else if (image) {
    return <ImgBg image={image} height={50} className="full-width" />
  } else {
    return <figure><LegalDocLg/></figure>
  }
}

const SearchCard = ({entry}) => {
  const image = entry.image ? entry.image[0] : null
  let date = entry.eventDate ? new Date(entry.eventDate).toLocaleString("en-US", {timeZone: "America/New_York"}) : null

  return <React.Fragment>
    <Tag tag={entry} />
    <CardImg result={entry} image={image} />
    <div className="card-content">
      <div>
        {entry.date ? <span className="publish-date">{entry.date}</span>: null}
        {entry.eventDate ? <span className="publish-date">{formatDateTime(date)}</span>: null}
        <h3>{entry.title}</h3>
      </div>
      {entry.heading ? <p className="body-text">{truncateText(entry.heading,300)}</p> : null}
    </div>
  </React.Fragment>
}
