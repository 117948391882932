import React from "react";

const SvgNews = props => (
  <svg
    id="news_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 326 217"
    {...props}
  >
    <defs>
      <style>{".news_svg__cls-1{fill:#e9e9e6}"}</style>
    </defs>
    <path className="news_svg__cls-1" d="M0 0h326v217H0z" />
    <path
      fill="#fff"
      d="M234.53 57.34H91.47v93.88h93.5l9.47 18.19 9.47-18.19h30.62V57.34z"
    />
    <path
      className="news_svg__cls-1"
      d="M139.31 85.43h80.16v8.12h-80.16zM139.31 101.57h80.16v8.12h-80.16zM104.77 117.71H219.7v8.12H104.77z"
    />
    <path fill="#e85352" d="M104.77 109.69l13.02-25.01 13.02 25.01h-26.04z" />
  </svg>
);

export default SvgNews;
