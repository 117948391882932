import React from "react"
import { useParams, Link } from "react-router-dom"
import { useFetchStoreVars } from "Fetch"
import PageHeader from "blocks/PageHeader"
import Image from "blocks/Image"
import BodyText from "blocks/BodyText"
import { ExternalLink } from "blocks/Button"
import Sidebar from "blocks/Sidebar"
import Loading from "blocks/Loading"
import ArticleLayout from "components/ArticleLayout"
import { formatDate, formatTime } from "helpers"
import useBreakpoint from "useBreakpoint"
import { training } from "queries"

export default () => {
  let { slug } = useParams()
  const data = useFetchStoreVars(training, { slug: slug }, `training-${slug}`)
  return data ? <Page entry={data.entries[0]} /> : <Loading />
}

const Page = ({ entry }) => {
  let mobile = useBreakpoint() !== "desktop"
  let image = entry.image[0]
  let date = new Date(entry.eventDate).toLocaleString("en-US", { timeZone: "America/New_York" })

  return <div className={mobile ? "" : "pad-lg"}>
    <ArticleLayout side="left">
      <Sidebar
        entry={entry}
        top={
          <div>
            {image ? <Image className="full-width" image={image} /> : null}
            {entry.linkUrl ? <div className="button-actions pad-top pad-bottom">
              <ExternalLink linkUrl={entry.linkUrl} linkCta={"Register"} linkStyle={"defaultRed button-lg"} linkTitle={"Register for training"} />
            </div> : null}
          </div>
        }
        bottom={null}
      />
      <div>
        <PageHeader className="">
          <ul className="breadcrumbs red">
            <li><span>Work</span></li>
            <li><Link to={"/work/what-we-do"} title={`go to what we do page`}><span>What We Do</span></Link></li>
            <li><Link to={"/work/what-we-do/technical-assistance"} title={`go to technical assistance page`}><span>Training &amp; Technical Assistance</span></Link></li>
          </ul>
          <h1>{entry.title}</h1>
          <p className="header-details">{formatDate(date, true)}</p>
          <p className="header-details">{formatTime(date)}</p>
          <p className="header-details">{entry.copy}</p>
        </PageHeader>
        <div className="article">
          <h2>{entry.heading}</h2>
          <BodyText body={entry.body} />
          {entry.linkUrl ? <div className="button-actions pad-top pad-bottom">
            <ExternalLink linkUrl={entry.linkUrl} linkCta={"Register"} linkStyle={"defaultRed button-lg"} linkTitle={"Register for training"} />
          </div> : null}
        </div>
      </div>
    </ArticleLayout>
  </div>
}
