import React, { useState } from "react"
import { Link, NavLink, useLocation } from "react-router-dom"
import { useFetchStore } from "Fetch"
import { useTransition, useSpring, a } from "react-spring"
import { fetchData } from "redux/actions";
import { query } from "./query"
import SearchForm from "blocks/SearchForm"
import LanguageNav from "blocks/LanguageNav"
import Logo from "svgr/Logo"
import useBreakpoint from "useBreakpoint"
import scrollHandler from "scrollHandler"

import "./Nav.scss"

const Nav = () => {
  const [scroll, setScroll] = useState({
    y: typeof window !== "undefined" ? window.pageYOffset : null,
    compress: false
  })
  let mobile = useBreakpoint() !== "desktop"
  const [navOpen, setNavOpen] = useState(false)
  const navData = useFetchStore(query, "global-nav")

  function onScroll() {
    let scrollY = typeof window !== "undefined" && window.pageYOffset >= 0 ? -1 * (window.pageYOffset) : null
    setScroll(prev => ({
      y: scrollY,
      compress: (prev.y > scrollY) && !navOpen
    }))
  }

  scrollHandler(onScroll, 500)

  const style = scroll.compress ? {
    height: "var(--navHeightSm)"
  } : {
      height: "var(--navHeight)"
    }

  return <>
    <a href="#main" className="skip-to-main-content-link">Skip to main content</a>
    <nav className={`nav`} style={style}>
      <div className="nav__links">
        <Link to="/" className="site-logo" title="homepage">
          <Logo />
        </Link>
        {navData && <Links data={navData} mobile={mobile} navOpen={navOpen} setNavOpen={setNavOpen} />}
      </div>
      <LanguageNav compress={scroll.compress} />
      {mobile ? <MenuButton setNavOpen={setNavOpen} navOpen={navOpen} setScroll={setScroll} />
        : null}
    </nav>
  </>
}

const Links = ({ data, mobile, navOpen, setNavOpen }) => {
  const [subnavOpen, setSubnavOpen] = useState()
  const [searchOpen, setSearchOpen] = useState(false)
  const navLinks = data.globalSets[0].globalNav
  const location = useLocation()
  const style = useSpring({
    opacity: (navOpen || !mobile) ? 1 : 0,
    pointerEvents: (navOpen || !mobile) ? 'all' : 'none'
  })

  return <div className="nav__site-links-wrap">
    <a.ul className="nav__site-links" style={style}>
      {navLinks.map((link, i) => {
        switch (link.typeHandle) {
          case 'navItem':
            const sublinks = link.children.reduce((result, block) => {
              result.push(`/${block.page[0].uri}`)
              block.children.map(li => result.push(`/${li.page[0].uri}`))
              return result
            }, [])
            const isActive = sublinks.includes(location.pathname)
            return <li key={link.id}>
              <SubnavLabel
                id={link.id}
                text={link.copy}
                setSubnavOpen={setSubnavOpen}
                subnavOpen={subnavOpen}
                className={`nav-item top-nav${isActive ? " active" : ""}`}
              />
              <Subnav
                items={link.children}
                subnavOpen={subnavOpen}
                parentId={link.id}
                mobile={mobile}
                setNavOpen={setNavOpen}
                setSubnavOpen={setSubnavOpen}
              />
            </li>

          case 'navPageLink':
            return link.copy === "Search" ?
              <li key={link.id}>
                <NavLink
                  className={`nav-item top-nav ${searchOpen ? "active" : ""}`}
                  to={`/${link.page[0].uri}`}
                  onClick={(e) => {
                    e.preventDefault()
                    setSearchOpen(!searchOpen)
                  }}
                  onBlur={() => { setSearchOpen(false) }}
                >{link.copy}</NavLink>
                <SearchBar searchOpen={searchOpen} setSearchOpen={setSearchOpen} mobile={mobile} setNavOpen={setNavOpen} />
              </li>
              : <li key={link.id}>
                <NavLink
                  className={`nav-item top-nav`}
                  to={`/${link.page[0].uri}`}
                  onClick={() => { setNavOpen(false) }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") { setNavOpen(false) }
                  }}
                >{link.copy}</NavLink>
              </li>

          case 'navButton':
            const linkto = link.children[0]
            return <li key={link.id} className="nav-button">
              <NavLink
                className={!mobile ? `button-small ${link.buttonStyle}` : 'nav-item'}
                to={`/${linkto.page[0].uri}`}
                onClick={() => { setNavOpen(false) }}
                onKeyPress={(e) => {
                  if (e.key === "Enter") { setNavOpen(false) }
                }}
              >{linkto.copy}</NavLink>
            </li>

          default:
            return null
        }
      })}
    </a.ul>
  </div>
}

const closed = {
  opacity: 0
}
const open = {
  opacity: 1
}

const SubnavLabel = ({ id, text, setSubnavOpen, className, subnavOpen }) => {
  const [key, setKey] = useState();
  const isActive = subnavOpen === id
  return <div
    tabIndex="0"
    className={`nav-subnav__label ${className} ${isActive ? 'active open' : ''}`}
    onClick={() => { setSubnavOpen(isActive ? false : id) }}
    // onBlur={() => { setSubnavOpen(null) }}
    onKeyPress={(e) => {
      setKey(e.key)
      setSubnavOpen(e.key === key ? null : id)
    }}
  >
    <span>{text}</span>
  </div>
}

const Subnav = ({ items = [], subnavOpen, parentId, mobile, setNavOpen, setSubnavOpen }) => {
  const isOpen = subnavOpen && subnavOpen === parentId
  const t = useTransition(isOpen, null, {
    from: closed,
    enter: open,
    leave: closed
  });

  const closeNavs = () => {
    setSubnavOpen(false)
    setNavOpen(false)
  }

  return t.map(({ item, key, props }) => (item && <a.ul
    key={key}
    className={"nav-subnav"}
    style={props}>

    {items.map(item => {
      const hasChildren = !!item.children.length
      let subNavClasses = !mobile ? (hasChildren ? "subnav-item-wrap" : "subnav-item") : ""

      return <li key={item.id} className={subNavClasses}>
        <NavLink
          to={`/${item.page[0].uri}`}
          className={`subnav-link ${hasChildren ? "flex-row" : "col"}`}
          onClick={closeNavs}
          onKeyPress={(e) => {
            if (e.key === "Enter") { closeNavs() }
          }}
        >
          <span className={mobile ? `nav-item ${hasChildren ? "nav-item-parent" : ""}` : "subnav-item-parent"}>{item.heading}</span>
          {item.copy && !mobile ? <p className="subnav-item-description">{item.copy}</p> : null}
        </NavLink>
        {hasChildren && <ul style={(item.children.length > 3 && !mobile) ? {
          display: "grid",
          gridTemplateColumns: "1fr 1fr"
        } : {
            paddingTop: 0
          }}>{item.children.map(child =>
            <li key={child.id}>
              <NavLink
                className={`nav-item`}
                to={`/${child.page[0] ? child.page[0].uri : 'test'}`}
                onClick={closeNavs}
                onKeyPress={(e) => {
                  if (e.key === "Enter") { closeNavs() }
                }}
              >
                {child.heading}
              </NavLink>
            </li>
          )}</ul>}
      </li>
    })}
  </a.ul>))
}

const SearchBar = ({ searchOpen, setSearchOpen, mobile, setNavOpen }) => {
  const t = useTransition(searchOpen, null, {
    from: closed,
    enter: open,
    leave: closed
  })

  return t.map(({ item, key, props }) => (item && <a.div
    key={key}
    style={props}
    className={"nav-search"}>
    <SearchForm active={setSearchOpen} setNavOpen={setNavOpen} />
    {mobile ? "" :
      <button className="button white button-xs close-button" onClick={
        () => { setSearchOpen(false) }}>
        <span className="icon close"></span>
      </button>
    }
  </a.div>))
}

const MenuButton = ({ open, setNavOpen, navOpen, setScroll }) => {
  return <button className={`menu-toggle${navOpen ? " is-open" : ""}`} onClick={() => {
    setNavOpen(!navOpen)
    setScroll({ compress: false })
  }}>
  </button>
}

export default Nav

Nav.serverFetch = [
  () => fetchData(query, "global-nav")
]
