import React, {useState,useEffect} from "react"
import {Link} from "react-router-dom"
import {useSpring,a} from "react-spring"
import cookie from "react-cookies";

const langs = [
  {code: "en", name: "English"},
  {code: "es", name: "Español"},
  {code: "fr", name: "Français"},
  {code: "zh-CN", name: "中文"}
]

export default ({compress}) => {
  const [language,setLanguage] = useState(cookie.load("lang") ? cookie.load("lang") : "en")
  const style = useSpring({
    height: (!compress) ? 50 : 0
  })

  const resetLang = () => {
    let iframe = document.getElementById(":1.container")
    let close = iframe ? iframe.contentDocument.getElementById(":1.close") : null

    if (close) {
      if ("createEvent" in document) {
        let evt = document.createEvent("HTMLEvents")
        evt.initEvent("click", false, true)
        close.dispatchEvent(evt)
      } else {
        close.fireEvent("onclick")
      }
    }
    return
  }

  useEffect(() => {
    let googleTag = document.getElementById("google_translate_element")
    let select = googleTag ? googleTag.querySelector("select") : null

    if (language === "en") {
      resetLang()
    } else {
      document.documentElement.lang = `${language}-x-mtfrom-en`
      let linkRel = document.createElement("link")
      linkRel.rel = "alternate machine-translated-from"
      linkRel.hrefLang = "en"
      linkRel.href = process.env.PUBLIC_SITE_URL
      document.head.appendChild(linkRel)

      if (select) {
        select.value = language

        if ("createEvent" in document) {
          let evt = document.createEvent("HTMLEvents")
          evt.initEvent("change", false, true)
          select.dispatchEvent(evt)
        } else {
          select.fireEvent("onchange")
        }
      }
    }
  }, [language])

  return <a.ul
    className={"nav__langs"}
    style={style}>
    {langs.map(lang => <li key={lang.code}>
      <Link
        to="#"
        className={`nav-lang${language === lang.code ? " active" : ""}`}
        title={`Switch to ${lang.name}`}
        onClick={() => {
          if(lang.code === language) return
          setLanguage(lang.code)
          cookie.save("lang", lang.code, { path: "/" })
        }}
      >{lang.name}</Link>
    </li>
    )}
  </a.ul>
}
