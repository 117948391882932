import React, {useState,useRef,useEffect} from "react"
import {useLocation,useHistory} from "react-router-dom"
import Search from "svgr/Search"

export default ({active, setNavOpen}) => {
  let { search } = useLocation()
  let searchQ = search && search.split('?query=')[1]
  const [typing,setTyping] = useState(false)
  const [query,setQuery] = useState(searchQ ? decodeURI(searchQ) : null)
  const formRef = useRef()
  const history = useHistory()

  useEffect(() => {
    if (active) {formRef.current.focus()}
  },[active])

  const submitForm = (e) => {
    if (!query) return
    e.preventDefault()
    history.push(`/search?query=${encodeURI(query)}`)
    active(true)
    setNavOpen(false)
  }

  return <form
    action="/search"
    id="search-form"
    onFocus={()=>{
      active(true)
    }}
    onBlur={()=>{
      active(false)
      setTyping(false)
    }}
    onSubmit={submitForm}>
    {!typing && !query ? <label htmlFor="query">Search</label> : null}
    <input type="text" name="query" tabIndex="-1" ref={formRef} onChange={(e) => {
      setQuery(e.currentTarget.value)
    }} value={query ? query : ""} />
    <button onClick={submitForm} className="button white button-xs">
      <span className="icon"><Search /></span>
    </button>
  </form>
}
