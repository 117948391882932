// polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch'
import smoothscroll from 'smoothscroll-polyfill';
import cssVars from 'css-vars-ponyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import createStore from "redux/store.js";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

cssVars();
smoothscroll.polyfill();

const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrate;
const store = createStore(window.REDUX_DATA);

renderMethod((
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
