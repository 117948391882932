import { imageAsset } from "queries"

export const person = `query($slug: [String]) {
  entries(section: "people", slug: $slug, unique: true) {
    id
    title
    uri
    slug
    ...on people_people_Entry {
      id
      typeHandle
      title
      heading
      copy
      email
      slug
      image {
        ${imageAsset}
      }
      body
    }
    ...on people_boardMember_Entry {
      id
      typeHandle
      uri
      title
      heading
      copy
      email
      body
      slug
    }
  }
}`

export const people = `query {
  entries(section: "people", type: ["people", "boardMember"]) {
    ...on people_people_Entry {
      id
      typeHandle
      uri
      title
      heading
      copy
      email
      slug
      image {
        ${imageAsset}
      }
    }
    ...on people_boardMember_Entry {
      id
      typeHandle
      uri
      title
      heading
      copy
      email
      slug
    }
  }
}
`

export const staffIds = `query {
  entries(section: "people", type: ["people","nonStaffMember"]) {
    ...on people_people_Entry {
      id
    }
    ...on people_nonStaffMember_Entry {
      id
    }
  }
}`
