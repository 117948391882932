import React from "react"
import {Link} from "react-router-dom";
import {HashLink} from "react-router-hash-link"
import ReactGA from "react-ga";

import "./Button.scss"

export default (link) => {
  return (link.typeHandle === "internalLink") ?
    <InternalLink
      pageEntry={link.page[0]}
      hashLink={link.hashLink}
      linkCta={link.linkCta}
      linkStyle={link.linkStyle}
    />
    : <ExternalLink
      linkUrl={link.linkUrl}
      linkCta={link.linkCta}
      linkStyle={link.linkStyle}
      linkTitle={link.linkTitle}
    />
}

export const InternalLink = ({
  pageEntry,
  hashLink,
  linkCta,
  linkStyle
}) => {
  if (pageEntry && hashLink) {
    return <HashLink to={`/${pageEntry.uri}#${hashLink}`} className={`button ${linkStyle}`} title={`go to ${pageEntry.title} page`}>
      {linkCta ? linkCta : (pageEntry ? pageEntry.title : "Learn More")}
    </HashLink>
  } else if (pageEntry) {
    return <Link to={`/${pageEntry.uri}`} className={`button ${linkStyle}`} title={`go to ${pageEntry.title} page`}>
      {linkCta ? linkCta : (pageEntry ? pageEntry.title : "Learn More")}
    </Link>
  } else {
    return null
  }
}

export const ExternalLink = ({
  linkUrl,
  linkCta,
  linkStyle,
  linkTitle
}) => {
  return linkUrl ? <ReactGA.OutboundLink
    to={linkUrl}
    target="_blank"
    rel="noopener noreferrer"
    className={`button ${linkStyle}`}
    title={linkTitle}
    eventLabel={linkUrl}>
    {linkCta}
  </ReactGA.OutboundLink> : null
}
