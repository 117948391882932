import React from "react"
import SocialShare from "blocks/SocialShare"
import SectionHeader from "blocks/SectionHeader"
import {sortData} from "blocks/Accordion"
import {TabLink} from "blocks/LinkList"
import {priorityColor} from "colors"
import useBreakpoint from "useBreakpoint"
import {useFetchStoreVars} from "Fetch"


const Sidebar = ({top,bottom,entry}) => {
  let mobile = useBreakpoint() === "mobile"

  return <div className={`sidebar ${mobile ? "resort-end" : ""}`}>
    <div className="">
     {top}
     <div className="pad-bottom">
       <SectionHeader className={"small"} heading={"Share this page"} />
       <SocialShare title={entry.title} url={entry.uri} copy={entry.heading} />
      </div>
     {bottom ? <SidebarLinks {...bottom} /> : null}
    </div>
  </div>
}

const SidebarLinks = ({
  heading,
  query,
  type,
  parent,
  current,
  relatedTo,
  tag,
  limit=4,
  max
}) => {
  const data = useFetchStoreVars(query, {id: current, relatedTo: relatedTo, parent: parent, type: type, tag: tag}, `${current}-related-to-${relatedTo}`)
  let results = data ? sortData(data.entries) : null
  let finalResults = max && results ? results.slice(0,max) : results

  return results ? <React.Fragment>
    <SectionHeader className={"small"} heading={heading} />
    {finalResults.map(link => (
      <TabLink
        key={link.id}
        page={link}
        color={link.parent ? priorityColor[link.parent.id] : null}
        image={link.image ? link.image[0] : null}
      />
    ))}
  </React.Fragment> : null
}

export default Sidebar
