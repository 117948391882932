import { useState, useEffect } from "react";
import throttle from "lodash/throttle";

export default () => useBreakpoint(
  { mobile: 0, tablet: 768, desktop: 1024 },
  'desktop'
);

const determineBreakpoint = (width,breakpoints) => {
  const sorted = Object.keys(breakpoints)
    .sort((a,b) => {
      return breakpoints[a] - breakpoints[b]
    })

  return sorted.reduce((r,breakpoint) => {
    if (window.innerWidth >= breakpoints[breakpoint]) {
      r = breakpoint
    }
    return r
  },sorted[0])
}

const useBreakpoint = (config,defaultBreakpoint) => {
  const [breakpoint, setBreakpoint] = useState(defaultBreakpoint || Object.keys(config)[0]);

  useEffect(() => {
    const calcInnerWidth = throttle(function() {
      setBreakpoint(determineBreakpoint(window.innerWidth,config))
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    calcInnerWidth()
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, [config]);

  return breakpoint;
}
