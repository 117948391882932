import React from "react"
import {useFetchStoreVars} from "Fetch"
import {shopQuery} from "./query"
import {ResourceCard} from "components/Resources/Resources"

export default () => {
  const data = useFetchStoreVars(shopQuery, {}, `shop-resources`)
  return data ? <section className="pad-top pad-bottom max-width">
    <ul className="grid grid--33" style={{margin: '3rem 0'}}>
      {data.entries.map(resource => {
        return <li key={`resource_${resource.id}`}>
          <ResourceCard resource={resource} />
        </li>
      })}
    </ul>
  </section> : null
}
