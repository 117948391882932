import React from "react"
import {useParams} from "react-router-dom"
import {useFetchStoreVars} from "Fetch"
import FourOhFour from "components/FourOhFour"
import ChapteredPage from "components/ChapteredPage"
import Hero from "blocks/Hero"
import Event from "blocks/Event"
import Donate from "blocks/Donate"
import Loading from "blocks/Loading"
import PageBuilder from "./PageBuilder"
import Shop from "blocks/Shop"
import Module from "./Module"
import checkAnchor from "checkAnchor"

import {
  aboutPageQuery,
  donateQuery,
  fundraisingQuery,
  defaultPage
} from "queries"

const Page = ({match}) => {
  let { slug } = useParams();
  let query, vars;

  switch(slug) {
    case "mission":
      vars = {slug: slug}
      query = aboutPageQuery
      break;
    case "impact":
      vars = {slug: slug}
      query = aboutPageQuery
      break;
    case "donate":
      query = donateQuery
      break;
    case "events":
      query = fundraisingQuery
      break;
    default:
      vars = {slug: slug}
      query = defaultPage
      break;
  }

  const data = useFetchStoreVars(query, vars, `${slug}`)

  return <section className="page">
    {data ? <PageType data={data} /> : <Loading />}
  </section>
}

const PageType = ({data}) => {
  let entry = data.entries[0]

  if (!entry) {
    return <FourOhFour />
  }

  checkAnchor()

  switch(entry.sectionHandle) {
    case "donate":
      return <SupportPage entry={entry} />
    case "chapteredPage":
      return <ChapteredPage page={entry} />
    case "eventLanding":
      return <SupportPage entry={entry} />
    default:
      return <article>
       <Hero
         image={entry.heroModule ? entry.heroModule[0].image : null}
         heading={entry.heroModule ? entry.heroModule[0].heading : entry.title}
         copy={entry.heroModule ? entry.heroModule[0].copy : null}
         styleType={"default"}
       />
       <PageBuilder data={data.entries[0].pageBuilder} slug={entry.slug}/>

       {entry.id === 16236 || entry.title === "Shop" ? <Shop /> : null}
     </article>
  }
}

const SupportPage = ({entry}) => {
  return <article>
    {entry.heroModule ? <Hero
      {...entry.heroModule[0]}
      styleType={`default`}
    /> : <Hero
      heading={entry.heading}
      copy={entry.copy}
      image={entry.image}
      styleType={`default${entry.title === "Donate" ? " head-blue" : ""}`}>
      {entry.sectionHandle === "donate" ? <Donate embed={entry.embed} /> : null}
    </Hero>}
    {entry.sectionHandle === "donate" ? null : <div dangerouslySetInnerHTML={{ __html: entry.embed }} />}
    {entry.featuredEvent ? <Event events={entry.featuredEvent} type="events" /> : null}
    {entry.modules.map(mod => <Module key={mod.id} module={mod} />)}
  </article>
}


export default Page
