import React from "react"
import {useParams} from "react-router-dom"
import {useFetchStoreVars} from "Fetch"
import SectionHeader from "blocks/SectionHeader"
import RelatedContent from "components/RelatedContent"
import Related from "components/Resources/Related"
import Module from "components/Module"
import TextLink from "blocks/TextLink"
import Sidebar from "blocks/Sidebar"
import Article from "blocks/Article"
import Loading from "blocks/Loading"
import {WorkHero} from "blocks/Hero"
import {priorityColor} from "colors"
import {
  priority as query,
  priorities as allPriorities
} from "./query"

import useBreakpoint from "useBreakpoint"

import ArticleLayout from "components/ArticleLayout"

const Priority = () => {
  let { slug } = useParams();
  const data = useFetchStoreVars(query, {slug: slug}, `priority-${slug}`)
  return data ? <Page data={data} /> : <Loading />
}

const Page = ({data}) => {
  const entry = data.entries[0]
  let tags = entry.children.map(issue => issue.id)

  return <section>
    <WorkHero
      title={entry.title}
      image={entry.image[0]}
      color={priorityColor[entry.id]}
    />
    <ArticleLayout side="left">
      <Sidebar
        entry={entry}
        top={false}
        bottom={{heading: "Our Other Priorities", query: allPriorities, current: entry.id}}
      />
      <Article heading={entry.copy} copy={entry.body} />
    </ArticleLayout>
    <IssueAreas bgColor={priorityColor[entry.id]} issues={entry.children} />
    <RelatedContent heading="Related News" current={entry.id} relatedTo={tags} />
    <Related current={entry.id} relatedTo={tags} limit={3} manualResources={entry.manualResources} />
    {entry.modules.map(mod => <Module key={mod.id} module={mod} />)}
  </section>
}

const IssueAreas = ({bgColor, issues}) => {
  let mobile = useBreakpoint() === "mobile"
  return <div className="pad-top pad-bottom" style={!mobile ? {backgroundColor: `var(--bg-${bgColor})`} : {}}>
    <SectionHeader heading={"Issue Areas"} />
    <ul className="grid grid--33 max-width">
      {issues.map(issue =>
        <TextLink
          key={issue.id}
          entry={issue}
          title={issue.title}
          copy={issue.heading}
          className={`inline bg-${bgColor} hide-copy-mobile`}
        />
      )}
    </ul>

  </div>
}


export default Priority
