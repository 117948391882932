import React from "react"
import useBreakpoint from "useBreakpoint"

const ArticleLayout = ({side, children}) => {
  let mobile = useBreakpoint() === "mobile"
  return <article className={`page-grid grid--${side}-col article-layout max-width pad-top pad-bottom ${mobile ? "reorder" : ""}`}>
    {children.map(Comp => Comp)}
  </article>
}

export default ArticleLayout
