import React from "react";

const SvgReportLg = props => (
  <svg data-name="Layer 1" viewBox="0 0 372 372" {...props}>
    <path fill="#e9e9e6" d="M0 0h372v372H0z" />
    <path fill="#fff" d="M109.3 266.84l90.13-161.68 90.13 161.68H109.3z" />
    <path fill="#e85352" d="M82.44 220.12l60.11-107.83 60.1 107.83H82.44z" />
  </svg>
);

export default SvgReportLg;
