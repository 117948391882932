import React from "react"
import { useLocation } from "react-router-dom"
import { useFetchStoreVars } from "Fetch"
import { DetailHero } from "blocks/Hero"
import Loading from "blocks/Loading"
import Sidebar from "blocks/Sidebar"
import PageBuilder from "components/PageBuilder"
import RelatedContent from "components/RelatedContent"
import Related from "components/Resources/Related"
import ArticleLayout from "components/ArticleLayout"
import { priorityColor } from "colors"
import checkAnchor from "checkAnchor"
import {
  issueArea as query,
  issueAreas
} from "./query"

import "./Work.scss"

const Priority = ({ match }) => {
  const slug = match.params.slug
  const data = useFetchStoreVars(query, { slug: slug }, `issue-${slug}`)
  return data ? <Page data={data} /> : <Loading />
}

const Page = ({ data }) => {
  const entry = data.entries[0]
  const location = useLocation()

  checkAnchor()

  let crumbs = location.pathname.split("/").reduce((res, loc, i) => {
    let dest = location.pathname.split(loc)[0] === "/" ? null : location.pathname.split(loc)[0]
    if (loc && loc !== entry.slug) {
      res[loc] = dest ? `${dest}${loc}` : null
    }
    return res
  }, {})

  let hero = entry.heroModule[0]

  return <section>
    <DetailHero
      title={hero ? hero.heading : entry.title}
      heading={hero ? hero.copy : entry.copy}
      image={hero ? hero.image[0] : null}
      color={priorityColor[entry.parent.id]}
      className={"reverse"}
      breadcrumb={crumbs}
    />
    <ArticleLayout side="left">
      <Sidebar
        entry={entry}
        top={false}
        bottom={{ heading: entry.parent.title, query: issueAreas, current: entry.id, parent: entry.parent.id }}
      />
      <PageBuilder data={entry.pageBuilder} />
    </ArticleLayout>
    <RelatedContent heading={"Related News"} current={entry.id} relatedTo={entry.id} />
    <Related current={entry.id} relatedTo={entry.id} limit={3} manualResources={entry.manualResources} />
  </section>
}


export default Priority
