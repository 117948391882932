import React from "react"
import { Helmet } from "react-helmet"
import ReactHtmlParser from "react-html-parser"
import {useFetchStoreVars} from "Fetch"
import {seo as query} from "queries"

export default ({slug}) => {
  const seo = useFetchStoreVars(query, {uri: slug}, `seo-${slug}`)
  return seo ? <Helmet>
    {ReactHtmlParser(seo.seomatic.metaTitleContainer)}
    {ReactHtmlParser(seo.seomatic.metaTagContainer)}
  </Helmet> : null
}
