import React from "react"
import {Link} from "react-router-dom"
import Image from "blocks/Image"
import {ExternalLink} from "blocks/Button"
import {OutboundLink} from "react-ga";
import {formatDateTime} from "helpers"

const Event = ({events, type}) => {
  let data = events.filter(evt => new Date(evt.eventDate) >= new Date()).sort((x,y) => new Date(x.eventDate) > new Date(y.eventDate) ? 1 : -1)

  return data && data.length ? <ul className="max-width grid grid--33" style={{
    marginBottom: "2rem",
    paddingBottom: "4rem"
  }}>
    {data.map((training,i) => {
      let featured = i === 0
      return training.typeHandle === "trainings" ? <Link
        key={training.id}
        to={`/${training.uri}`}
        className={`news-module training-card${featured ? " event-module-double" : " card"}${training.image[0] ? " card-with-img img--back" : " card-no-img"} gray`}
        title={`view ${training.title}`}>
        <Card training={training} featured={featured} />
      </Link> : training.linkUrl && !featured ? <OutboundLink to={training.linkUrl}
          key={training.id}
          className={`news-module training-card card${training.image[0] ? " card-with-img img--back" : " card-no-img"} gray`}
          target="_blank"
          rel="noopener noreferrer"
          title={`Go to ${training.title} event page`}
          eventLabel={training.linkUrl}>
        <Card training={training} featured={featured} />
      </OutboundLink> :
        <div key={training.id} className={`news-module training-card${featured ? " event-module-double" : " card"}${training.image[0] ? " card-with-img img--back" : " card-no-img"} gray`}>
          <Card training={training} featured={featured} />
        </div>
    })}
  </ul> : <p className="pad" style={{textAlign: "center"}}>No {type} at this time.</p>
}

const Card = ({training, featured}) => {
  let image = training.image[0]
  let buttonCopy = training.typeHandle === "trainings" ? "Learn More" : "RSVP Now"
  let date = new Date(training.eventDate).toLocaleString("en-US", {timeZone: "America/New_York"})

  return <React.Fragment>
    <aside className="tag card-tag">{training.typeHandle}</aside>
    {image ? <Image image={image} className="full-width" /> : <figure /> }
     <div className="card-content" style={{backgroundColor: `var(--bg-gray)`}}>
       <div>
         <span className="publish-date publish-date-heavy">{formatDateTime(date)}</span>
         <h3 style={{fontSize: `var(--size-h2)`}}>{training.title}</h3>
       </div>
       {featured && training.linkUrl ?
        <ExternalLink linkUrl={training.linkUrl} linkCta={buttonCopy} linkStyle={"defaultRed"} linkTitle={`Go to ${training.title} event page`} />
        : null}
     </div>
   </React.Fragment>
}

export default Event
