import React from "react"
import {useFetchStore} from "Fetch"
import PageTitle from "blocks/PageTitle"
import Loading from "blocks/Loading"
import {ImgBg} from "blocks/Image"
import ReactGA from "react-ga";
import {reports} from "queries"

const Reports = () => {
  const data = useFetchStore(reports,"reports")
  return data ? <section>
    <header className="pad-top pad-bottom max-width">
      <PageTitle heading={"Annual Reports"} />
    </header>
    <ReportCards reports={data.entries} />
  </section> : <Loading />
}

const ReportCards = ({reports}) => {
  return <ul className="grid grid--33 pad-top pad-bottom mobile-pad">
    {reports.map(report => {
      return <li key={report.id}>
        <ReactGA.OutboundLink
          className={`reset card report-card ${report.image[0] ? "card-with-img card-img-lg img--front" : "card-no-img"}`}
          to={report.file[0].url}
          title={`download ${report.title} file`}
          target="_blank"
          rel="noopener noreferrer"
          eventLabel={report.file[0].url}
          download>
          {report.image[0] ?
            <ImgBg image={report.image[0]} height={100} className="tall-img" />
            :
            <figure className="tall-img" style={{backgroundColor: "var(--bg-gray)"}} />
          }
          <div className="card-content resource-card" style={{
            backgroundColor: "var(--white)",
            border: "1px solid var(--gray)"
          }}>
            <h3>{report.title}</h3>
            <p className="inline-link">
              <span>Download</span>
              <span className="arrow" />
            </p>
          </div>
        </ReactGA.OutboundLink>
      </li>
    })}
  </ul>
}

export default Reports
