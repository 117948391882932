import React from "react"
import Frame from "blocks/Frame"
import { ImgBg } from "blocks/Image"
import { Link } from "react-router-dom"

import "./People.scss"

const People = ({ people }) => {
  return <ul className={`grid grid--${people[0].typeHandle === "people" ? "25" : "33"}`}>
    {people.map(person => {
      return person.typeHandle !== "nonStaffMember" ? <li key={person.id}>
        {person.typeHandle === "people" ? <Staff key={person.id} person={person} /> : <BoardMember key={person.id} person={person} />}
      </li> : null
    })}
  </ul>
}

const Staff = ({ person }) => {
  const image = person.image[0]
  const style = image ? {
    justifyContent: "space-between"
  } : {
      justifyContent: "flex-end"
    }
  return <Link to={`/about/our-team/${person.slug}`} title={`go to ${person.title}'s bio`} className="person staff-member" style={style}>
    {image ? <Frame color={"gray"}>
      <ImgBg image={image} height={100} />
    </Frame> : null}
    <div>
      <h3>{person.title}</h3>
      <h4>{person.copy}</h4>
      {person.email ? <h4>{person.email}</h4> : null}
    </div>
  </Link>
}

const BoardMember = ({ person }) => {
  return <Link to={`/about/our-team/${person.slug}`} title={`go to ${person.title}'s bio`} className="person board-member">
    <div>
      <h3>{person.title}</h3>
      <h4>{person.copy}</h4>
      {person.email ? <h4>{person.email}</h4> : null}
    </div>
  </Link>
}

export default People
