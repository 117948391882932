import React from "react"
import {Link} from "react-router-dom"
import PageTitle from "blocks/PageTitle"

export default () => {
  return <div className="max-width article center pad-top pad-bottom full-height">
    <PageTitle heading="Oops" />
    <h2 className="type--center" style={{
      margin: "0 auto",
      padding: "2rem 0"
    }}>It looks like the page you are looking for doesn't exist...</h2>
    <Link to="/" className={`button defaultRed`} title={`go to homepage`}>
      Return home
    </Link>
  </div>
}
