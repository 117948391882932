import React from "react";

const SvgArrow = props => (
  <svg width={18} height={30} {...props}>
    <g fill="none" fillRule="evenodd" stroke="#041E26">
      <path d="M9 29.988l9-16H0zM9.5 0v13.988" />
    </g>
  </svg>
);

export default SvgArrow;
