import React from "react"
import { useSpring, a } from "react-spring";
import scrollHandler from "scrollHandler"
import SvgArrow from "svgr/Arrow"
import useBreakpoint from "useBreakpoint"

const ScrollToTop = () => {
  const desktop = useBreakpoint() === "desktop"

  const [style, setStyle] = useSpring(() => ({
    opacity: 0
  }))

  function onScroll() {
    let offset = typeof window !== "undefined" ? window.pageYOffset > window.innerHeight : null
    setStyle({opacity: offset ? 1 : 0})
  }

  function scrollToTop() {
    if (document.body.scrollTop !== 0 || document.documentElement.scrollTop !== 0) {
      window.scrollBy(0, -50);
      requestAnimationFrame(scrollToTop);
    }

    return false
  }

  scrollHandler(onScroll, 200)

  return <a.aside style={style} onClick={scrollToTop} className="ScrollToTop">
    <div style={{transform: `rotate(180deg)` }}><SvgArrow /></div>
    {desktop ? <p style={{ marginTop: 10 }}>Scroll to Top</p> : null}
  </a.aside>
}

export default ScrollToTop
