import React, { useState } from "react"
import { useFetchStoreVars } from "Fetch"
import useBreakpoint from "useBreakpoint"

const Accordion = ({ children, query, params, filter = null, limit = 6, route, buttonClass, addData = [] }) => {
  const [offset, setOffset] = useState(limit)
  const results = useFetchStoreVars(query, params, `${route}`)
  let data

  if (results) {
    if (filter && (filter.date || filter.type.length)) {
      data = filterData(results.entries, filter)
    } else {
      data = sortData(results.entries)
    }
  }

  // merge manual resources & then filter
  if (addData.length) {
    let arr = addData.concat(data)
    // to remove dups...
    data = Array.from(new Set(arr.filter(x => x).map(a => a.id)))
      .map(id => {
        return arr.find(a => a.id === id)
      })
  }

  return data ? <React.Fragment>
    {children(data.filter(x => x).slice(0, offset))}
    <ViewMoreButton
      buttonClass={buttonClass}
      setOffset={setOffset}
      offset={offset}
      limit={limit}
      total={data.length} />
  </React.Fragment> : null
}

export const sortData = (data) => {
  let results = data.sort((x, y) => {
    let xdate = x.date && x.date.indexOf(',') < 0 ? x.date.replace(' ', ' 1, ') : x.date
    let ydate = y.date && y.date.indexOf(',') < 0 ? y.date.replace(' ', ' 1, ') : y.date
    return new Date(xdate) > new Date(ydate) ? -1 : 1
  })

  return results
}


const filterType = (results, types) => {
  return results.filter(art => {
    let tag = art.newsTag || art.resourceTag
    if (art.typeHandle === "press") return art
    return tag && tag.length && types.indexOf(tag[0].id) > -1 ? art : null
  })
}

const filterDate = (results, date) => {
  return results.filter(art => {
    let year = new Date(art.date).getFullYear()
    return year >= date[0] && year <= date[1] ? art : null
  })
}

const filterData = (data, filter) => {
  let results = sortData(data)
  let articles

  if (filter.type.length && !filter.date) {
    articles = filterType(results, filter.type)
  } else if (!filter.type.length && filter.date) {
    articles = filterDate(results, filter.date)
  } else {
    let first = filterType(results, filter.type)
    articles = filterDate(first, filter.date)
  }

  return articles
}

export const ViewMoreButton = ({ setOffset, limit, offset, buttonClass = "", total }) => {
  let mobile = useBreakpoint() === "mobile"
  const style = mobile ? { margin: "2rem 0" } : { marginTop: "2rem" }
  return offset < total ? <div className={buttonClass}><button style={style} className={`button blackBorder`} onClick={() => {
    setOffset(off => off + limit)
  }}>See More</button></div> : null
}

export default Accordion
