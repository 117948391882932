const defaultState = {}

export default (state=defaultState, action) => {
  switch (action.type) {
    case "STORE_DATA":
      return {
        ...state,
        [action.query]: action.data
      }


    // case "LOAD_MORE_DATA":
    //   let oldData = state[action.query]
    //   // console.log(state[action.query])
    //   return {
    //     ...state,
    //     [action.query]: oldData ? [state[action.query], ...action.data] : action.data
    //   }

    default:
      return state
  }
}
