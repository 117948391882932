import React from "react";

const SvgInstagram = props => (
  <svg viewBox="0 2.5 8 8" {...props}>
    <path d="M4 10.1H2.7c-.3 0-.6-.1-1-.2C1 9.5.6 9 .5 8.2v-.7V7 5.1c0-.4.1-.7.2-1.1.3-.5.8-.9 1.5-1 .2-.1.5-.1.7-.1h2.4c.6 0 1.2.2 1.6.7.3.3.5.7.6 1.2v3.1c0 .4-.1.7-.3 1.1-.2.5-.7.8-1.3 1-.2.1-.4.1-.6.1-.4-.1-.9 0-1.3 0zM1.1 6.5v1.6c.1.7.8 1.3 1.5 1.3h3c.8-.1 1.3-.8 1.3-1.6V5.2v-.5c-.2-.7-.8-1.1-1.5-1.1H2.6c-.4 0-.8.2-1.1.5-.3.3-.4.6-.4 1v1.4z" />
    <path d="M5.8 6.5c0 1-.8 1.8-1.8 1.8s-1.8-.9-1.8-1.9c0-1 .8-1.8 1.8-1.8 1 .1 1.8.9 1.8 1.9zm-.7 0c0-.6-.5-1.1-1.1-1.1s-1.1.4-1.1 1.1c0 .6.5 1.1 1.1 1.1s1.1-.5 1.1-1.1zM5.9 4.2c.2 0 .4.2.4.4s-.2.4-.4.4-.3-.2-.4-.4c0-.2.2-.4.4-.4z" />
  </svg>
);

export default SvgInstagram;
