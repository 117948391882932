const childLink = `
...on globalNav_navLink_BlockType {
  id
  typeHandle
  copy
  heading
  page {
    id
    uri
    title
  }
`

const pageLink = `
...on globalNav_navPageLink_BlockType {
  id
  typeHandle
  copy
  page {
    id
    uri
    title
  }
}`

export const query = `query {
  globalSets(handle: "nav") {
    ...on nav_GlobalSet {
      globalNav {
        ...on globalNav_navItem_BlockType {
          id
          copy
          typeHandle
          children {
            ${childLink}
              children {
                ${childLink}}
              }
            }
          }
        }
        ${pageLink}
        ...on globalNav_navButton_BlockType {
          id
          typeHandle
          buttonStyle
          children {
            ${pageLink}
          }
        }
      }
    }
  }
}`
