import React from "react"
import {useHistory} from "react-router-dom"
import PageTitle from "blocks/PageTitle"

export default () => {
  const history = useHistory()

  return <div className="max-width article center pad-top pad-bottom full-height">
    <PageTitle heading="404" />
    <h2 className="type--center" style={{
      margin: "0 auto",
      padding: "2rem 0"
    }}>Page not found</h2>
    <button className={`button defaultRed`} title={`go back to previous page`} onClick={history.goBack}>
      Back
    </button>
  </div>
}
