import React, {useState} from "react"
import Image from "blocks/Image"
import BodyText from "blocks/BodyText"

export default ({blocks}) => {
  let total = blocks.length
  const limit = 4
  const [offset,setOffset] = useState(limit)

  return <div>
    <ul className="timeline max-width">
      {blocks.slice(0,offset).map(block => {
        return <li key={block.id} className="timeline-block">
          {block.image[0] ? <Image image={block.image[0]} /> : <figure/>}
          <div className="timeline-block_content">
            {block.date ? <h5>{block.date}</h5> : null}
            <h4>{block.heading}</h4>
            <BodyText body={block.body} />
          </div>
        </li>
      })}
    </ul>
  {offset < total ? <div className="center max-width"><button className="button blackBorder" onClick={()=>{
    setOffset((offset)=> offset+limit)
  }}>Load More</button></div> : null}
</div>
}
