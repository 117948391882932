import React from "react";
import "./SplitList.scss"

const SplitList = ({blocks}) => {
  return <div className="split-list grid grid--50 pad-top pad-bottom max-width">
    {blocks.map(block => (block.typeHandle === "headingBlock") ?
      <HeadingBlock key={block.id} {...block} /> :
      <ListBlock key={block.id} {...block} />
    )}
  </div>
}

const HeadingBlock = (block) => {
  return <div className="split-list-heading grid-block">
    <h3>{block.heading}</h3>
    <p dangerouslySetInnerHTML={{ __html: block.copy }} />
  </div>
}

const ListBlock = (block) => {
  return <div className="split-list-list grid-block">
    <h4>{block.heading}</h4>
    <div dangerouslySetInnerHTML={{ __html: block.body }} />
  </div>
}

export default SplitList
