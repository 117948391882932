import {useEffect} from "react"

import "./Forms.scss"

export default () => {
  useEffect(() => {
    if (document.getElementById("universal-code-snippet")) return

    const vars = document.createElement("script")
    vars.innerHTML = `var _ctct_m = "0d2e4d17e249b86f890871c1f24b6674";`

    const script = document.createElement("script")
    script.id = "universal-code-snippet"
    script.src = "//static.ctctcdn.com/js/signup-form-widget/current/signup-form-widget.min.js"
    script.async = true
    script.defer = true

    if (typeof document.body !== "undefined") {
      document.body.appendChild(vars)
      document.body.appendChild(script)
    }
  },[])

  return null
}
