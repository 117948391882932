import React from "react"
import {useParams} from "react-router-dom"
import {useFetchStoreVars} from "Fetch"
import PageHeader from "blocks/PageHeader"
import BodyText from "blocks/BodyText"
import Loading from "blocks/Loading"
import {plainPage} from "queries"

const PlainTextPage = ({match}) => {
  let { slug } = useParams();
  const data = useFetchStoreVars(plainPage, {slug: slug}, `${slug}`)
  let page = data ? data.entries[0] : null

  return page ? <section className="page plain-page">
    <PageHeader className="lift center">
      <h1>{page.title}</h1>
      <h2>{page.heading}</h2>
    </PageHeader>
    <BodyText body={page.body} />
  </section> : <Loading />
}

export default PlainTextPage
