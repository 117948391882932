import React from "react"
import SectionHeader from "blocks/SectionHeader"
import Accordion from "blocks/Accordion"
import { relatedNews as relatedQuery } from "./News/query"
import NewsModule from "blocks/NewsModule"

export default ({ heading, current, relatedTo, type, limit = 6, color = "light-gray", storyType = null }) => {
  return <section className="pad-top pad-bottom max-width">
    <SectionHeader heading={heading} />
    <Accordion
      limit={limit}
      query={relatedQuery}
      params={{ id: current, relatedTo: relatedTo, type: type ? type : [], storyType: storyType }}
      route={`${current}-content-related-to-${relatedTo}-${limit}`}
      buttonClass="center"
      sort={true}
    >
      {(data) => {
        if (!data) { return "Loading" }
        return <ul className="grid grid--33">
          {data.map((article, i) => {
            // let featured = (article.typeHandle === "action" || article.storyType === "clientStory") && i === 0
            return <li key={article.id}>
              <NewsModule article={article} color={color} />
            </li>
          })
          }
        </ul>
      }}
    </Accordion>
  </section>
}
