import React from "react"
import { useParams, useLocation } from "react-router-dom"
import { useFetchStoreVars, useFetchStore } from "Fetch"
import SectionHeader from "blocks/SectionHeader"
import Sidebar from "blocks/Sidebar"
import Article from "blocks/Article"
import People from "blocks/People"
import Event from "blocks/Event"
import { DetailHero } from "blocks/Hero"
import Loading from "blocks/Loading"
import { MajorProjects } from "./WorkPage"
import RelatedContent from "components/RelatedContent"
import Module from "components/Module"
import Related from "components/Resources/Related"
import {
  workAction as query,
  workActions as allWorkActions,
  trainings
} from "./query"

import checkAnchor from "checkAnchor"

import ArticleLayout from "components/ArticleLayout"

const WorkAction = () => {
  let { slug } = useParams();
  const data = useFetchStoreVars(query, { slug: slug }, `work-what-we-do-${slug}`)
  return data ? <Page data={data} /> : <Loading />
}

const Page = ({ data }) => {
  const entry = data.entries[0]
  const location = useLocation()
  let crumbs = location.pathname.split("/").reduce((res, loc, i) => {
    let dest = location.pathname.split(loc)[0] === "/" ? null : location.pathname.split(loc)[0]
    if (loc && loc !== entry.slug) {
      res[loc] = dest ? `${dest}${loc}` : null
    }
    return res
  }, {})

  checkAnchor()

  const experts = entry.entryAuthor

  return <section>
    <DetailHero
      title={entry.title}
      heading={entry.heading}
      image={entry.image[0]}
      color={"black"}
      breadcrumb={crumbs}
    />
    <ArticleLayout side="right">
      <Article heading={null} copy={entry.body} />
      <Sidebar
        entry={entry}
        top={false}
        bottom={{ heading: "How We Work", query: allWorkActions, current: entry.id }}
      />
    </ArticleLayout>

    {entry.slug === "coalitions-and-collaboration" ? <MajorProjects /> : null}

    {entry.slug === "technical-assistance" ? <Trainings /> :
      <div className="pad bg-light-gray" style={{ backgroundColor: 'var(--bg-light-gray)' }}>
        <RelatedModule entry={entry} />
      </div>
    }

    {experts.length ? <div className="max-width pad-top pad-bottom border-top border-bottom">
      <SectionHeader className="large" heading={"Experts"} />
      <People people={experts} />
    </div> : null}

    <Related current={entry.id} relatedTo={entry.id} limit={3} manualResources={entry.manualResources} />

    {entry.modules ? entry.modules.map(mod => <Module key={mod.id} module={mod} />) : null}
  </section>
}

const RelatedModule = ({ entry }) => {
  if (entry.slug === "direct-legal-services") {
    return <RelatedContent color="white" limit={6} heading="Client Stories" current={entry.id} relatedTo={entry.id} type="story" storyType="clientStory" />
  } else {
    return <RelatedContent color="white" limit={6} heading="Related News" current={entry.id} relatedTo={entry.id} />
  }
}

const Trainings = () => {
  const data = useFetchStore(trainings, "trainings")
  return data ? <div className="pad-top" id="upcoming-trainings">
    <SectionHeader className="large" heading={"Upcoming Training Sessions"} />
    <Event events={data.entries} type="trainings" />
  </div> : null
}

export default WorkAction
