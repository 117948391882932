export const splitTag = string => string ? string.replace(/(?=[A-Z ]+)/g, ' ') : ""

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
]

const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const formatDate = (date, dayOfWeek = false) => {
  let newDate = new Date(date)
  let day = dayOfWeek ? `${days[newDate.getDay()]}, ` : ""

  return day + monthNames[newDate.getMonth()] + " " + newDate.getDate() + ", " + newDate.getFullYear()
}

export const formatTime = (date) => {
  let newDate = new Date(date)
  let hours = newDate.getHours()
  let minutes = newDate.getMinutes()
  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours ? hours : 12
  minutes = minutes < 10 ? '0' + minutes : minutes
  let strTime = hours + ':' + minutes + ampm

  return strTime
}

export const formatDateTime = (date, dayOfWeek = false) => {
  return formatDate(date, dayOfWeek) + " " + formatTime(date)
}

export const truncateText = (copy, length) => {
  let sentence
  if (copy.length > length) {
    let string = copy.substring(0, Math.min(length, copy.length))
    sentence = string.substr(0, Math.min(string.length, string.lastIndexOf(" "))) + "..."
  } else {
    sentence = copy
  }
  return sentence
}
