import React from "react"
import ReactGA from "react-ga";

const OutboundLink = ({url, className, title, children}) => (
  <ReactGA.OutboundLink
    to={url}
    className={className}
    title={title}
    target="_blank"
    rel="noopener noreferrer"
    eventLabel={url}
  >{children}</ReactGA.OutboundLink>
)

export default OutboundLink
