import { useEffect } from "react"
import { useLocation } from "react-router-dom"

export default () => {
  const location = useLocation()
  const hash = location.hash.substring(1)

  function scrollTo() {
    const el = document.getElementById(hash)
    if (el) el.scrollIntoView()
  }

  useEffect(() => {
    if (hash && hash.length) {
      setTimeout(
        window.requestAnimationFrame(scrollTo)
      , 0)
    }

    return () => window.cancelAnimationFrame(scrollTo)
  })
}
