import {useLayoutEffect,useRef} from "react"

export default (callback,wait,dependency) => {
  let throttleTimeout = useRef(null)

  function onScroll() {
    callback()
    throttleTimeout.current = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout.current === null) {
          throttleTimeout.current = setTimeout(onScroll, wait)
        }
      } else {
        onScroll()
      }
    }

    if (!dependency) window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  })
}
