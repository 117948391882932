import React from "react"
import {Link,useHistory} from "react-router-dom"
import {useFetchStoreVars} from "Fetch"
import SocialShare from "blocks/SocialShare"
import PageHeader from "blocks/PageHeader"
import SectionHeader from "blocks/SectionHeader"
import BodyText from "blocks/BodyText"
import Loading from "blocks/Loading"
import Image from "blocks/Image"
import ResourceIcon from "blocks/ResourceIcon"
import Related from "./Related"
import useBreakpoint from "useBreakpoint"
import {pageQuery as query} from "./query"
import ReactGA from "react-ga";
import checkAnchor from "checkAnchor"

import "./Resources.scss"

const Resource = ({match}) => {
  const slug = match.params.slug
  const data = useFetchStoreVars(query, {slug: slug}, `resource-${slug}`)
  return data ? <ResourcePage data={data.entries[0]} /> : <Loading />
}

const SimpleResource = ({resource}) => {
  const history = useHistory()
  let url = resource.externalLink ? resource.externalLink : (resource.fileAsset[0] ? resource.fileAsset[0].url : null)
  if (url) {
    window.open(url, "_blank")
    history.push("/resources")
  }
  return null
}

const ResourcePage = ({data}) => {
  let mobile = useBreakpoint() === "mobile"

  if (data.typeHandle === "simpleResource" && data.resourceLink) {
    return <SimpleResource resource={data.resourceLink[0]} />
  }

  const {title,resourceTag,heading,date,image,file,body,embed} = data
  const authors = data.staffMember && data.staffMember.length ? data.staffMember : null
  let tags = data.relatedTag.map(tag => parseInt(tag.id))

  checkAnchor()

  return <React.Fragment>
    <section className={mobile ? "reorder pad" : "grid grid--left-col pad"}>
      <aside className={mobile ? "pad resort-end" : ""}>
        <div className="sticky">
          {image.length ?
            <Image image={image[0]} />
          : <ResourceIcon tag={resourceTag[0].title} /> }
          <SectionHeader heading="Share this page" className="small" />
          <SocialShare title={title} url={data.uri} copy={heading} />
        </div>
      </aside>
      <article>
        <PageHeader>
          <span className="resource-tag">{resourceTag[0].title}</span>
          <h1>{title}</h1>
          {authors ? <div className="resource-authors">
            {authors.map(author => {
              return author.typeHandle === "nonStaffMember" ?
                <p className="publish-date" key={`author_${author.id}`}>{author.title}</p>
                : <Link
                  style={{display: "block"}}
                  className="inline-link"
                  key={`author_${author.id}`}
                  to={`/about/our-team/${author.slug}`}
                  title={`View ${author.title} bio page`}>{author.title}</Link>
            })
            }</div>
          : null}
          <p className="publish-date">{date}</p>
        </PageHeader>

        <div className="resource-body">
          {heading ? <h2 className="page-subheader">{heading}</h2> : null}
          <ul className="grid grid--33 pad-bottom">
          {file.length ? file.map(download =>
            <li key={download.id} className="download-button">
              <p>{download.title}</p>
              <ReactGA.OutboundLink
                to={download.url}
                className="button button blackBorder"
                style={{marginBottom: "5px"}}
                target="_blank"
                rel="noopener noreferrer"
                title={`down ${download.title} pdf`}
                eventLabel={download.url}
                download>Download</ReactGA.OutboundLink>
            </li>)
          : null}
          </ul>
          <BodyText body={body} />
        </div>
        {embed ? <div className="pad-top pad-bottom" dangerouslySetInnerHTML={{ __html: embed }} /> : null}
      </article>
    </section>

    <Related current={data.id} relatedTo={tags.filter(n => n)} limit={3} manualResources={data.manualResources} />
  </React.Fragment>
}

export default Resource
