import React from "react"
import BodyText from "./BodyText"
import "blocks/PageBlocks.scss"

const Article = ({heading,copy, children}) => {
  return <div className="article">
    <h2>{heading}</h2>
    <BodyText body={copy} />
    {children}
  </div>
}

export default Article
