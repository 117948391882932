import { imageAsset, pageBuilder, modules, manualResources } from "queries"

export const pageQuery = `
query($slug:[String]) {
  entries(section: "work", slug: $slug) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_work_Entry {
      heading
      modules {
        ${modules}
      }
    }
  }
}`

export const priorities = `query($id:QueryArgument) {
  entries(section: "work", type: "priority", id: ["not", $id]) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_priority_Entry {
      typeHandle
      title
      heading
      copy
      image {
        ${imageAsset}
      }
      children {
        ...on work_issueArea_Entry {
          id
          title
          uri
        }
      }
    }
  }
}`

export const priority = `query($slug: [String]) {
  entries(section: "work", type: "priority", slug: $slug, unique: true) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_priority_Entry {
      title
      heading
      copy
      image {
        ${imageAsset}
      }
      body
      modules {
        ${modules}
      }
      children {
        ...on work_issueArea_Entry {
          id
          title
          heading
          uri
        }
      }
      ${manualResources}
    }
  }
}`

export const workActions = `query($id:QueryArgument) {
  entries(section: "work", type: "workAction", id: ["not", $id]) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_workAction_Entry {
      typeHandle
      title
      heading
      image {
        ${imageAsset}
      }
      children {
        ...on work_issueArea_Entry {
          id
          title
          uri
        }
      }
      modules {
        ${modules}
      }
    }
  }
}`

export const workAction = `query($slug: [String]) {
  entries(section: "work", type: "workAction", slug: $slug, unique: true) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_workAction_Entry {
      title
      heading
      slug
      image {
        ${imageAsset}
      }
      body
      parent {
        id
        title
        slug
        parent {
          id
          slug
        }
      }
      ${manualResources}
      entryAuthor: staffMember {
        id
        ...on people_people_Entry {
          id
          slug
          typeHandle
          title
          heading
          copy
          email
          image {
            ${imageAsset}
          }
        }
        ...on people_boardMember_Entry {
          id
          slug
          typeHandle
          title
          heading
          copy
          email
        }
      }
      children {
        ...on work_issueArea_Entry {
          id
          title
          uri
        }
      }
      modules {
        ${modules}
      }
    }
  }
}`

export const majorProjects = `query {
  entries(section: "majorProject") {
    id
    title
    uri
    sectionHandle
    ...on majorProject_majorProject_Entry {
      image {
        ${imageAsset}
      }
    }
  }
}`

export const issueArea = `query($slug: [String]) {
  entries(section: "work", type: "issueArea", slug: $slug) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_issueArea_Entry {
      shortDescription: heading
      parent {
        id
        title
        slug
      }
      heroModule {
        ...on heroModule_hero_BlockType {
          image {
            ${imageAsset}
          }
          heading
          copy
        }
      }
      ${pageBuilder}
      ${manualResources}
    }
  }
}`

export const issueAreas = `query($parent:Int,$id:QueryArgument) {
  entries(section: "work", id: ["not", $id], type: "issueArea", descendantOf: $parent) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_issueArea_Entry {
      shortDescription: heading
      parent {
        id
      }
    }
  }
}`

export const relatedIssues = `query($id:[Int]) {
  entries(section: "work", type: "issueArea", relatedTo: $id) {
    id
    title
    uri
    slug
    sectionHandle
    ...on work_issueArea_Entry {
      shortDescription: heading
      parent {
        id
      }
    }
  }
}`

export const project = `query($slug: [String]) {
  entries(section: "majorProject", slug: $slug) {
    id
    title
    uri
    slug
    sectionHandle
    ...on majorProject_majorProject_Entry {
      image {
        ${imageAsset}
      }
      heroImage {
        ${imageAsset}
      }
      heading
      copy
      body
      factBlock {
        ...on factBlock_fact_BlockType {
          id
          heading
          subheading
          copy
        }
      }
      ${pageBuilder}
      ${manualResources}
    }
  }
}`

export const trainings = `query {
  entries(section: "events", type: "trainings") {
    id
    title
    uri
    slug
    sectionHandle
    typeHandle
    ...on events_trainings_Entry {
      image {
        ${imageAsset}
      }
      eventDate @formatDateTime
      linkUrl
    }
  }
}`
