import React from "react";

const SvgPresentationLg = props => (
  <svg data-name="Layer 1" viewBox="0 0 372 372" {...props}>
    <path fill="#e9e9e6" d="M0 0h372v372H0z" />
    <path
      fill="#fff"
      d="M290.24 235.86V87.73H81.76v148.13h95.94v35.73h-42.69v12.68h101.44v-12.68h-46.07v-35.73h99.86z"
    />
    <path d="M232.06 164.09l-73.67-38.32v76.64z" fill="#e85352" />
  </svg>
);

export default SvgPresentationLg;
