import React from "react"
import { useParams } from "react-router-dom"
import { useFetchStoreVars } from "Fetch"
import FactBlock from "blocks/FactBlock"
import Loading from "blocks/Loading"
import PageBuilder from "components/PageBuilder"
import Related from "components/Resources/Related"
import RelatedContent from "components/RelatedContent"
import FourOhFour from "components/FourOhFour"
import { WorkHero } from "blocks/Hero"
import {
  project as query,
  relatedIssues
} from "./query"
import checkAnchor from "checkAnchor"

import ArticleLayout from "components/ArticleLayout"
import Sidebar from "blocks/Sidebar"
import Article from "blocks/Article"

const MajorProject = () => {
  let { slug } = useParams();
  const data = useFetchStoreVars(query, { slug: slug }, `major-project-${slug}`)
  return data ? <Page data={data} /> : <Loading />
}

const Page = ({ data }) => {
  const entry = data.entries[0]
  checkAnchor()

  if (!entry) {
    return <FourOhFour />
  }

  return <section className="red">
    <WorkHero
      title={entry.title}
      image={entry.heroImage[0] ? entry.heroImage[0] : entry.image[0]}
      color={"red"}
    />
    <ArticleLayout side="left">
      <Sidebar
        entry={entry}
        top={false}
        bottom={{ heading: "Associated Issue Areas", query: relatedIssues, current: entry.id, relatedTo: entry.id }}
      />
      <Article heading={entry.copy} copy={entry.body} />
    </ArticleLayout>
    <FactBlock data={entry.factBlock} />
    <PageBuilder data={entry.pageBuilder} />

    <RelatedContent heading={"Related News"} limit={6} current={entry.id} relatedTo={entry.id} />
    <Related current={entry.id} relatedTo={entry.id} limit={3} manualResources={entry.manualResources} />
  </section>
}

export default MajorProject
