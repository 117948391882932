import React from "react"
import {Link} from "react-router-dom";
import ReactGA from "react-ga";

export default ({pageEntry,linkCta}) => {
  return pageEntry && pageEntry.uri ? <Link to={`/${pageEntry.uri}`} className={`inline-link`} title={`go to ${pageEntry.title} page`}>
    {linkCta ? linkCta : pageEntry.title}
    <span className="arrow" />
  </Link> : <ReactGA.OutboundLink
    to={pageEntry}
    target="_blank"
    rel="noopener noreferrer"
    className={`inline-link`}
    title={`leave site to go to ${pageEntry}`}
    eventLabel={pageEntry}
  >
    {linkCta}
    <span className="arrow" />
  </ReactGA.OutboundLink>
}
