import React from "react"
import SectionHeader from "../../blocks/SectionHeader"
import Accordion from "blocks/Accordion"
import Loading from "blocks/Loading"
import {relatedQuery} from "./query"
import {ResourceCard} from "./Resources"

import "./Resources.scss"

export default ({
  limit,
  current,
  relatedTo,
  manualResources=[],
  heading="Related Resources"
}) => {
  let none = manualResources[0] && manualResources[0].hideResources
  let resources = manualResources[0] && manualResources[0].resources

  return !none ? <section className="pad-top pad-bottom max-width">
    <SectionHeader heading={heading} />
    <Accordion
      query={relatedQuery}
      params={{id: current, relatedTo: relatedTo}}
      route={`${current}-resource-related-to-${relatedTo}`}
      limit={limit}
      buttonClass="center"
      addData={resources}
    >
    {(data) => {
      if (!data) return <Loading />
      return data.length > 0 ? <ul className="grid grid--33" style={{margin: '3rem 0'}}>
        {data.map(resource => {
          return <li key={`resource_${resource.id}`}>
            <ResourceCard resource={resource} />
          </li>
        })}
      </ul> : null
    }}
  </Accordion>
  </section> : null
}

// const ManualResources = ({resources,limit}) => {
//   const [offset,setOffset] = useState(limit)

//   return resources ? <React.Fragment>
//     <List data={resources.slice(0,offset)} />
//     <ViewMoreButton
//       buttonClass={"center"}
//       setOffset={setOffset}
//       offset={offset}
//       limit={limit}
//       total={resources.length} />
//   </React.Fragment> : null
// }

// const List = ({data}) => {
//   return <ul className="grid grid--33" style={{margin: '3rem 0'}}>
//     {data.map(resource => {
//       return <li key={`resource_${resource.id}`}>
//         <ResourceCard resource={resource} />
//       </li>
//     })}
//   </ul>
// }
