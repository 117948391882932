import { imageAsset, linkTo, manualResources } from "queries"

const relatedTags = `
relatedTag {
  ...on work_issueArea_Entry {
    id
    typeHandle
    slug
  }
}`

export const relatedNews = `
query($id:QueryArgument,$relatedTo:[Int],$type:[String],$tag:[Int],$limit:Int,$storyType:[QueryArgument]) {
  entries(section: ["news", "press"], type: $type, id: ["not", $id], limit: $limit, relatedTo: $relatedTo, relatedToAll: $tag, storyType: $storyType) {
    id
    title
    uri
    sectionHandle
    ...on news_news_Entry {
      title
      typeHandle
      newsTag {
        id
        title
        slug
      }
      heading
      image {
        ${imageAsset}
      }
      date
    }
    ...on news_story_Entry {
      typeHandle
      storyType
      heading
      image {
        ${imageAsset}
      }
      copy
      date
    }
    ...on news_profile_Entry {
      typeHandle
      heading
      image {
        ${imageAsset}
      }
      copy
      date
    }
    ...on news_action_Entry {
      typeHandle
      heading
      date
      image {
        ${imageAsset}
      }
    }
    ...on press_press_Entry {
      typeHandle
      source: copy
      date
      linkUrl
      image {
        ${imageAsset}
      }
    }
  }
}`

const author = `
staffMember {
  id
  title
  slug
  typeHandle
}`

export const newsQuery = `query($slug: [String]) {
  entries(section: "news", slug: $slug, orderBy: "dateUpdated desc") {
    id
    title
    uri
    ...on news_action_Entry {
      typeHandle
      heading
      date
      image {
        ${imageAsset}
      }
      heroImage {
        ${imageAsset}
      }
      imageAsBackground
      body
      embed
      ${linkTo}
      ${relatedTags}
      ${manualResources}
    }
    ...on news_news_Entry {
      title
      typeHandle
      newsTag {
        id
        title
        slug
      }
      heading
      date
      body
      embed
      image {
        ${imageAsset}
      }
      heroImage {
        ${imageAsset}
      }
      imageAsBackground
      author: ${author}
      ${relatedTags}
    }
    ...on news_story_Entry {
      typeHandle
      storyType
      heading
      image {
        ${imageAsset}
      }
      date
      copy
      body
      embed
      author: ${author}
      ${relatedTags}
    }
    ...on news_profile_Entry {
      typeHandle
      heading
      image {
        ${imageAsset}
      }
      date
      copy
      body
      embed
      author: ${author}
    }
  }
}`

export const storiesQuery = `
query($id:QueryArgument,$relatedTo:[Int],$limit:Int) {
  entries(section: "news", type: "story", id: ["not", $id], limit: $limit, relatedTo: $relatedTo) {
    id
    title
    uri
    sectionHandle
    ...on news_story_Entry {
      typeHandle
      storyType
      heading
      image {
        ${imageAsset}
      }
      copy
      date
    }
  }
}
`