import React from "react"
import SectionHeader from "../SectionHeader"
import "./FactBlock.scss"

const FactBlock = ({data, children}) => {
  return <div className={"facts-block pad-lg"}>
    <SectionHeader heading={"The Facts"} />
    <ul className="grid grid--25">
      {data.map(block => <Fact key={block.id} {...block} />)}
    </ul>
    {children}
  </div>
}

const Fact = ({heading,subheading,copy}) => {
  return <li className="fact">
    <h3>{heading}</h3>
    <h4>{subheading}</h4>
    <div dangerouslySetInnerHTML={{ __html: copy }} />
  </li>
}

export default FactBlock
